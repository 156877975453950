import React from "react";
import {
  GiCaptainHatProfile,
  GiFlowerHat,
  GiOutbackHat,
  GiBackup,
} from "react-icons/gi";
import { FaRedhat } from "react-icons/fa";
import { ImUserCheck } from "react-icons/im";
import opgg from "../assets/opgg.webp";
import { TbCoins } from "react-icons/tb";
import {
  top,
  jungler,
  mid,
  adc,
  support,
  coach,
  analyst,
  BOTH,
  LAN,
  LAS,
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
} from "./AssetsBarrel";

export const PlayerCard = ({
  member,
  role,
  region,
  name,
  position,
  elo,
  rank,
  lp,
  active,
  tier,
  summoner,
  user_id,
  avatar_id,
  coins,
}) => {
  const getRoleIcon = (role) => {
    switch (role) {
      case "Top":
        return top;
      case "Jungler":
        return jungler;
      case "Mid":
        return mid;
      case "ADC":
        return adc;
      case "Support":
        return support;
      case "Coach":
        return coach;
      case "Analyst":
        return analyst;
      default:
        return null;
    }
  };
  const roleImages = {
    Top: top,
    Jungler: jungler,
    Mid: mid,
    ADC: adc,
    Support: support,
    Coach: coach,
    Analyst: analyst,
  };
  const getRegionIcon = (region) => {
    switch (region) {
      case "LAN":
        return LAN;
      case "LAS":
        return LAS;
      case "LAN/LAS":
        return BOTH;
      default:
        return null;
    }
  };

  const getEloIcon = (tier) => {
    switch (tier) {
      case "CHALLENGER":
        return challenger;
      case "GRANDMASTER":
        return grandmaster;
      case "MASTER":
        return master;
      case "DIAMOND":
        return diamond;
      case "EMERALD":
        return emerald;
      case "PLATINUM":
        return platinum;
      case "GOLD":
        return gold;
      case "SILVER":
        return silver;
      case "BRONZE":
        return bronce;
      case "IRON":
        return iron;
      default:
        return null;
    }
  };

  return (
    <div
      className="myGradient flex justify-between items-center py-2 px-4 rounded-md text-white
    text-xs sm:text-sm md:text-base transform transition-transform hover:border hover:border-yellow-500"
    >
      <div className="flex gap-2 items-center">
        {position ? (
          <div className="p-3.5 bg-fifth text-white rounded text-xl">
            {position}
          </div>
        ) : null}
      </div>
      <div className="flex-1 items-center justify-start text-center flex flex-col sm:flex-row gap-2 sm:gap-4 relative md:left-14">
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            src={`https://cdn.discordapp.com/avatars/${String(
              user_id
            )}/${avatar_id}.png`}
            alt="Discord Avatar"
            className="rounded-full w-10 sm:w-12 bg-gray-400 bg-opacity-30"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
            }}
          />
          <img
            src={roleImages[role]}
            alt={`${role} Icon`}
            style={{
              position: "absolute",
              bottom: -3,
              right: -4,
              borderRadius: "50%",
            }}
            className="bg-fifth w-4 sm:w-6 mx-auto"
          />
        </div>
        <div className="text-center overflow-hidden whitespace-nowrap max-w-[5em] md:max-w-[10em]">
          {name.length > 15
            ? `${name.slice(0, 15).charAt(0).toUpperCase()}${name
                .slice(0, 15)
                .slice(1)}`
            : `${name.charAt(0).toUpperCase()}${name.slice(1)}`}
        </div>
      </div>

      <div className="flex-1 items-center justify-center text-center ">
        {member === "captain" ? (
          <GiCaptainHatProfile
            size={30}
            fill="white"
            style={{ display: "block", margin: "0 auto" }}
          />
        ) : member === "subcaptain" ? (
          <GiFlowerHat
            size={30}
            fill="white"
            style={{ display: "block", margin: "0 auto" }}
          />
        ) : member === "staff" ? (
          <GiOutbackHat
            size={30}
            fill="white"
            style={{ display: "block", margin: "0 auto" }}
          />
        ) : (
          <FaRedhat
            size={30}
            fill="white"
            style={{ display: "block", margin: "0 auto" }}
          />
        )}
        <div>{member}</div>
      </div>

      <div className="flex-1 items-center justify-center text-center hidden sm:block">
        <img
          src={getRegionIcon(region)}
          alt={region}
          width={30}
          style={{ display: "block", margin: "0 auto" }}
        />
        <div className="text-white">{region}</div>
      </div>
      <div
        onClick={() => {
          window.open(
            `https://www.op.gg/summoners/${region.toLowerCase()}/${encodeURIComponent(
              summoner
            )}`,
            "_blank"
          );
        }}
        className="flex-1 items-center justify-center text-center"
      >
        <img
          src={getEloIcon(tier)}
          alt={tier}
          className="w-10 sm:w-16 md:w-20 mx-auto"
        />
        <div className="text-white">
          <div>
            {tier.charAt(0).toUpperCase() + tier.slice(1).toLowerCase()} {rank}{" "}
            {lp} LP
          </div>
        </div>
      </div>
      <div className="flex-1 items-center justify-center text-center">
        <TbCoins style={{ display: "block", margin: "0 auto" }} />
        {coins}
      </div>
    </div>
  );
};
