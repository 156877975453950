import React, { useState } from "react";
import { Paragraph } from "../../components/Paragraph";
import ActionButton from "../../components/ActionButton";
import { BsDiscord } from "react-icons/bs";

const DiscordCheck = ({ check, setCheck }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const joinDiscord = async () => {
    try {
      setIsVerifying(true);

      const response = await fetch("/profile/api/check-discord/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      const data = await response.json();

      if (response.status === 200) {
        setCheck(true);
        setIsVerified(true);
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error calling the api/check-discord/ endpoint:", error);
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <>
      <div>
      <ActionButton
          primary
          onClick={joinDiscord}
          disabled={isVerifying || isVerified}
          customStyle={
            isVerified
              ? "discord-button-green"
              : isVerifying
              ? "discord-button"
              : "discord-button"
          }
          isVerified = {isVerified}
        >
          <div className="flex items-center">
            <BsDiscord className="mr-2" />
            {isVerifying ? "Verificando" : isVerified ? "Verificado" : "Verificar Discord"}
          </div>
        </ActionButton>
      </div>

      <div
        className={
          isModalOpen
            ? "modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90"
            : ""
        }
      >
        {isModalOpen && (
          <div className="modal-content bg-fifth p-4 rounded-xl">
          <div className="mb-4 text-white font-bold text-lg text-center">
            Unirse al servidor de Discord
          </div>
          <div className="mb-2 text-white text-center">
            ¡Para usar todas las funciones, primero únete a nuestro servidor de Discord!
          </div>
          <div className="text-center mt-4 flex gap-3 justify-center">
            <a
              href="https://discord.gg/77MUdPy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 sm:text-base hover:border-yellow-500 hover:border-opacity-60">
                <div className="flex items-center">
                  Unirse
                </div>
              </button>
            </a>
            <button
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 sm:text-base hover:border-yellow-500 hover:border-opacity-60"
              onClick={() => setIsModalOpen(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
        )}
      </div>
    </>
  );
};

export default DiscordCheck;
