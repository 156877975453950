import React from "react";
import Button from "../../components/Button";
const UserTeam = ({ userProfile }) => {

  return (
    <div className="min-w-full bg-gray-300 text-xs rounded-md p-2">
      {userProfile.status === "SIN_EQUIPO" ? (
        <div className="flex justify-between items-center">
            <div>
          Aún no estas en un equipo.
          <br />
          Únete a uno, o crea un equipo.
          </div>
          <div>
            <Button link="/league/new-team">Crear Equipo</Button>
          </div>
        </div>
      ) : (
        <div>Estas en un equipo.</div>
      )}
    </div>
  );
};

export default UserTeam;
