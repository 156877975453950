import React, { useState, useEffect } from "react";
import Axios from "axios";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { IoIosCreate } from "react-icons/io";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import Flag from "react-world-flags";
import {
  top,
  jungler,
  mid,
  adc,
  support,
  coach,
  analyst,
} from "../../components/AssetsBarrel";
const CreatePage = ({ userData }) => {
  const [teamData, setTeamData] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [comment, setComment] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [currentTime, setcurrentTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentTimes, setCurrentTimes] = useState({});
  const [selectedTimes, setSelectedTimes] = useState({});
  const [isWorking, setIsWorking] = useState(false);
  const [isDateTimeValid, setIsDateTimeValid] = useState(true);
  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);


  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const navigate = useNavigate();
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();

      const mexicoTime = now.toLocaleTimeString("en-US", {
        timeZone: "America/Mexico_City",
      });
      const argentinaTime = now.toLocaleTimeString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });

      setCurrentTimes({
        mexico: mexicoTime,
        argentina: argentinaTime,
      });
      setcurrentTime(now); 

    };

    const interval = setInterval(updateTime, 1000);
    updateTime();


    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setIsWaiting(true); 

    if (userData && userData.team_info && userData.team_info.team_name) {
      Axios.get(`/api/team/${userData.team_info.team_name}`)
        .then((response) => {
          setTeamData(response.data);
          setIsWaiting(false); 
        })
        .catch((error) => {
          console.error("Error fetching team data:", error);
          setIsWaiting(false); 
        });
    }
  }, [userData]);

  const handlePlayerSelection = (playerId) => {
    if (selectedPlayers.includes(playerId)) {
      setSelectedPlayers((prev) => prev.filter((id) => id !== playerId));
    } else if (selectedPlayers.length < 7) {
      setSelectedPlayers((prev) => [...prev, playerId]);
    }
  };

  useEffect(() => {
    Axios.get("/api/get-csrf-token/")
      .then((response) => {
        setCsrfToken(response.data.csrfToken);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const currentTime = new Date();
    const twoWeeksLater = new Date(currentTime);
    twoWeeksLater.setDate(currentTime.getDate() + 14);
    const maxDateValue = twoWeeksLater.toISOString().split("T")[0];
    setMaxDate(maxDateValue);
  }, [currentTime]);

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const minDateValue = tomorrow.toISOString().split("T")[0];
    setMinDate(minDateValue);
  }, [currentTime]);

  const roleImages = {
    Top: top,
    Jungler: jungler,
    Mid: mid,
    ADC: adc,
    Support: support,
    Coach: coach,
    Analyst: analyst,
  };


  const handleScrimSubmission = async (e) => {
    setIsWorking(true);
    e.preventDefault();
    setIsLoading(true);
  
    if (selectedPlayers.length >= 2 && selectedPlayers.length <= 7) {
      try {
        // Convertir la fecha y hora seleccionadas a la zona horaria de México
        const localDateTime = calculateLocalTimes(selectedDate, selectedTime, userTimeZone);
  
        const response = await Axios.post(
          `/team/create-scrim/`,
          {
            players: selectedPlayers,
            comments: comment,
            date: localDateTime.mexico, // Usar la fecha y hora ajustada a México
            time: localDateTime.mexico.split(" ")[1], // Usar solo la hora ajustada a México
          },
          {
            headers: {
              "X-CSRFToken": csrfToken,
            },
          }
        );
  
        if (response.data.detail) {
          setModalContent({
            type: "success",
            message: response.data.detail,
            data: {
              players: selectedPlayers.map(
                (id) =>
                  teamData.members.find((member) => member.id === id).discord_tag
              ),
              comments: comment,
              date: localDateTime.mexico.split(" ")[0], // Mostrar solo la fecha ajustada a México
              time: localDateTime.mexico.split(" ")[1], // Mostrar solo la hora ajustada a México
            },
          });
          setModalOpen(true);
        }
      } catch (error) {
        console.error("Error submitting the scrim:", error);
        if (error.response && error.response.data) {
          setModalContent({
            type: "error",
            message: error.response.data.error || "Error submitting the scrim",
          });
          setModalOpen(true);
        }
      }
    } else {
      setModalContent({
        type: "error",
        message: "Select between 2 to 7 players",
      });
      setModalOpen(true);
    }
    setIsWorking(false);
    setIsLoading(false);
  };
  

  const Modal = ({ isOpen, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
          {children}
        </div>
      </div>
    );
  };
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const handleDateTimeChange = (e) => {
    if (
      e.target.type === "date" &&
      e.target.value >= currentTime.toISOString().split("T")[0]
    ) {
      setSelectedDate(e.target.value);
      setDate(e.target.value);
      setIsDateTimeValid(true);
    } else if (e.target.type === "time") {
      setSelectedTime(e.target.value);
      setTime(e.target.value);
      setIsDateTimeValid(true); 
    } else {
      setIsDateTimeValid(false); 
    }
  };
  const calculateLocalTimes = (date, time, userTimeZone) => {
    // Convertir la fecha y hora seleccionadas a un objeto Date en la zona horaria del usuario
    const selectedDateTime = new Date(`${date}T${time}`);
    const options = { timeZone: userTimeZone };
  
    // Obtener las horas en México y Argentina ajustadas según la zona horaria del usuario
    const mexicoDateTime = new Date(selectedDateTime.toLocaleString('en-US', { ...options, timeZone: 'America/Mexico_City' }));
    const argentinaDateTime = new Date(selectedDateTime.toLocaleString('en-US', { ...options, timeZone: 'America/Argentina/Buenos_Aires' }));
  
    // Función para formatear la fecha y hora en el formato deseado (YYYY-MM-DD HH:mm)
    const formatDate = (dateTime) => {
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, "0");
      const day = String(dateTime.getDate()).padStart(2, "0");
      const hours = String(dateTime.getHours()).padStart(2, "0");
      const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
  
    // Formatear las fechas y horas en el formato deseado y devolverlas como objeto
    return {
      mexico: formatDate(mexicoDateTime),
      argentina: formatDate(argentinaDateTime),
    };
  };

  useEffect(() => {
    if (selectedTime && selectedDate) {
      const localTimes = calculateLocalTimes(selectedDate, selectedTime, userTimeZone);
      setSelectedTimes(localTimes);
    }
  }, [selectedTime, selectedDate, userTimeZone]);
  return (
    <Container>
      {(isLoading || isWaiting) && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.7)", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            width: "100%",
            height: "100%",
          }}
        >
          <Loader/>
        </div>
      )}
      <div className="flex flex-col gap-2 mt-4">
        <TitleContainer
          text={"Agenda un scrim"}
          icon={<IoIosCreate />}
          color="--primary-color"
          flex="start"
        />
        {teamData && (
          <form
            onSubmit={handleScrimSubmission}
            className="bg-primary p-4 rounded shadow text-white"
          >
            <div className="flex flex-col gap-2">
              <div className="text-center font-bold mt-4">Horario Actual:</div>
              <div className="mt-4 flex justify-center gap-2 items-center">
                <Flag code="mex" width={20} /> {currentTimes.mexico}
                <Flag code="arg" width={20} /> {currentTimes.argentina}
              </div>
              <div className="text-center text-yellow-500 font-bold mt-4 mb-4">
                Los scrims deben ser agendados en tu hora local
              </div>
            </div>
            <div className="text-center text-white font-bold text-2xl	mb-4">
              Selecciona tus jugadores para buscar un scrim
            </div>
            <div className="grid grid-cols-1 gap-4">
              {teamData.members
                .filter((member) =>
                  teamData.players.includes(member.discord_tag)
                )
                .map((member) => (
                  <label
                    key={member.id}
                    className={`inline-flex gap-2 items-center p-2 bg-fifth rounded border-2 border-transparent ${
                      selectedPlayers.includes(member.id)
                        ? " border-2 border-yellow-500"
                        : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      className="mr-3 accent-yellow-500"
                      onChange={() => handlePlayerSelection(member.id)}
                      checked={selectedPlayers.includes(member.id)}
                    />

                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={`https://cdn.discordapp.com/avatars/${String(
                          member.user_id
                        )}/${member.avatar_id}.png`}
                        alt="Discord Avatar"
                        className="rounded-full w-10 sm:w-12 bg-gray-400 bg-opacity-30 border-2 border-yellow-500"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
                        }}
                      />
                      <img
                        src={roleImages[member.role]}
                        alt={`${member.role} Icon`}
                        style={{
                          position: "absolute",
                          bottom: -3,
                          right: -4,
                          borderRadius: "50%",
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="bg-fifth bg-opacity-60"
                      />
                    </div>

                    <div className="text-center text">
                      {member.discord_tag} ({member.soloq_account__summonerName}
                      )
                    </div>
                  </label>
                ))}
            </div>

            <div className="mt-4">
              <textarea
                placeholder="Agregar un comentario"
                rows="4"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded resize-none text-black"
              ></textarea>
            </div>

            <div className="mt-4 flex flex-wrap justify-center gap-6">
              <label className="flex flex-col items-center">
                Fecha:
                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleDateTimeChange}
                  max={maxDate}
                  min={minDate}
                  className="p-2 mt-1 border border-gray-300 rounded text-black"
                />
              </label>
              <label className="flex flex-col items-center">
                Hora:
                <input
                  type="time"
                  value={selectedTime}
                  onChange={handleDateTimeChange}
                  className="p-2 mt-1 border border-gray-300 rounded text-black"
                />
              </label>
            </div>
            <div className="mt-4 flex justify-center">
              <div className="flex flex-row justify-center gap-4 items-center">
                <label className="flex flex-col items-center justify-center text-center">
                  <strong>Hora Seleccionada (Mexico):</strong>
                  <span>{selectedTimes.mexico || "Esperando horario..."}</span>
                </label>
                <label className="flex flex-col items-center justify-center text-center">
                  <strong>Hora Seleccionada (Argentina):</strong>
                  <span>
                    {selectedTimes.argentina || "Esperando horario..."}
                  </span>
                </label>
              </div>
            </div>

            <div className="mt-4 flex justify-center gap-2 items-center">
              <button
                type="submit"
                className={`modal-button bg-fifth rounded-md text-white font-bold text-xs py-2 px-4 border-2 ${
                  !isDateTimeValid ||
                  selectedPlayers.length < 1 ||
                  !selectedDate ||
                  !selectedTime ||
                  isWorking
                    ? "bg-gray-400 cursor-not-allowed opacity-20 hover:bg-gray-400"
                    : "border-transparent hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                }`}
                disabled={
                  !isDateTimeValid ||
                  selectedPlayers.length < 1 ||
                  !selectedDate ||
                  !selectedTime ||
                  isWorking
                }
              >
                Programar Scrim
              </button>
            </div>
          </form>
        )}
      </div>
      <Modal isOpen={isModalOpen}>
        {modalContent.type === "success" ? (
          <>
            <p className="font-bold text-sm">{modalContent.message}</p>
            <ul>
              <li>
                <span className="font-bold text-sm">Jugadores:</span>{" "}
                <span className="text-xs">
                  {modalContent.data.players.join(", ")}
                </span>
              </li>
              {modalContent.data.comments ? (
                <>
                  <li>
                    <span className="font-bold text-sm">Comentarios:</span>{" "}
                    <span className="text-xs">
                      {modalContent.data.comments}
                    </span>
                  </li>
                </>
              ) : null}
              <li>
                <span className="font-bold text-sm">Fecha:</span>{" "}
                <span className="text-xs">{modalContent.data.date}</span>
              </li>
              <li>
                <span className="font-bold text-sm">Hora:</span>{" "}
                <span className="text-xs">{modalContent.data.time}</span>
              </li>
            </ul>
            <button
              onClick={() => navigate("/league/scrims")}
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
            >
              Ver Scrims
            </button>
          </>
        ) : (
          <>
            <p>{modalContent.message}</p>
            <button
              onClick={() => setModalOpen(false)}
              className="mt-4 block mx-auto bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default CreatePage;
