import React from "react";
import Button from "../../components/Button";

const ScrimCreate = () => {
  return (
    <div className="flex flex-col gap-2 bg-blue-50 p-2 rounded px-6 py-4 border-2 border-yellow-500">
      <div className="font-semibold text-lg">Agendar Scrim War</div>

      <div className="flex flex-row justify-between items-center">
        <div className="text-light text-md">
          Asegúrate de tener al menos 5 jugadores en el equipo.
        </div>{" "}
        <Button link={"/league/scrims/create"}>Crear Scrim</Button>
      </div>
    </div>
  );
};

export default ScrimCreate;
