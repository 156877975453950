import React from "react";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import {
  top,
  jungler,
  mid,
  adc,
  support,
  coach,
  analyst,
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
} from "./AssetsBarrel";

const TransferCard = ({
  date,
  player,
  discordId,
  discordAvatar,
  tier,
  rank,
  lp,
  summonerName,
  role,
  fa,
  from,
  to,
  region,
}) => {
  const tierImages = {
    CHALLENGER: challenger,
    GRANDMASTER: grandmaster,
    MASTER: master,
    DIAMOND: diamond,
    EMERALD: emerald,
    PLATINUM: platinum,
    GOLD: gold,
    SILVER: silver,
    BRONCE: bronce,
    IRON: iron,
  };

  const roleImages = {
    Top: top,
    Jungler: jungler,
    Mid: mid,
    ADC: adc,
    Support: support,
    Coach: coach,
    Analyst: analyst,
  };

  if (region !== "LAN" && region !== "LAS") {
    region = "LAN";
  }
  const opggUrl = `https://www.op.gg/summoners/${region}/${summonerName}`;

  return (
    <div className="myGradient flex justify-between items-center py-4 px-8 rounded-md text-white text-xs sm:text-sm md:text-base transform transition-transform hover:border hover:border-yellow-500 hover:scale-105 custom-wrap-pretty">
      <div className="flex-1 items-center justify-center text-center">
        {date}
      </div>
      <div className="flex flex-col md:flex-row items-center flex-1 text-center gap-2 md:gap-5 justify-start relative max-w-none md:left-3 ">
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            src={`https://cdn.discordapp.com/avatars/${String(
              discordId
            )}/${discordAvatar}.png`}
            alt="Discord Avatar"
            className="rounded-full w-10 sm:w-12 bg-gray-400 bg-opacity-30"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
            }}
          />
          <img
            src={roleImages[role]}
            alt={`${role} Icon`}
            style={{
              position: "absolute",
              bottom: -3,
              right: -4,
              borderRadius: "50%",
              width: "1.5rem",
              height: "1.5rem",
            }}
            className="bg-fifth"
          />
        </div>

        <div className="text-center overflow-hidden whitespace-nowrap max-w-[5em] md:max-w-[10em]">
          {player.length > 15
            ? `${player.slice(0, 15).charAt(0).toUpperCase()}${player
                .slice(0, 15)
                .slice(1)}`
            : `${player.charAt(0).toUpperCase()}${player.slice(1)}`}
        </div>
      </div>

      <div className="flex-col flex-1 items-center sm:flex-row gap-0 sm:gap-2 sm:items-center hidden sm:flex">
        <div>
          <a href={opggUrl} target="_blank" rel="noopener noreferrer">
            <img src={tierImages[tier]} alt={tier} className="w-10 sm:w-16" />
          </a>
        </div>
        <div className="flex flex-col min-w-1/6 flex-1 text-center">
          <div>
            <a href={opggUrl} target="_blank" rel="noopener noreferrer">
              {summonerName}
            </a>
          </div>
          <div>
            <a href={opggUrl} target="_blank" rel="noopener noreferrer">
              {tier.charAt(0).toUpperCase() + tier.slice(1).toLowerCase()}{" "}
              {rank} {lp} LP
            </a>
          </div>
        </div>
      </div>

      <div className="flex-1 items-center justify-center text-center">
        {from}
      </div>
      <div className="flex-1 items-center justify-center text-center">
        {fa ? (
          <FaArrowRightArrowLeft
            className="text-green-500 text-2xl md:text-4xl"
            style={{ display: "block", margin: "0 auto" }}
          />
        ) : (
          <FaArrowRightArrowLeft
            className="text-red-500 text-2xl md:text-4xl"
            style={{ display: "block", margin: "0 auto" }}
          />
        )}
      </div>
      <div className="flex-1 items-center justify-center text-center">{to}</div>
    </div>
  );
};

export default TransferCard;
