import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Images from "../../components/ImagesContainer";
import Flag from "react-world-flags";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
} from "../../components/AssetsBarrel";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { PlayerCard } from "../../components/PlayerCard";
import CardLoader from "../../components/CardLoader";
import { DateTime } from "luxon";

const ScrimCard = ({ userData, scrim, handleAcceptClick, isCaptain }) => {
  const [expanded, setExpanded] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const goToTeamPage = (teamName) => {
    navigate(`/league/equipos/${teamName}`);
  };

  const fetchTeamData = async () => {
    setIsLoading(true);
    try {
      const teamResponse = await fetch(`/api/team/${scrim.team}`);
      const teamData = await teamResponse.json();

      const scrimPlayersResponse = await Axios.get(
        `/api/scrim-request-players/${scrim.id}/`
      );
      const scrimPlayers = scrimPlayersResponse.data.map((player) => player.id);

      const filteredMembers = teamData.members.filter((member) =>
        scrimPlayers.includes(member.id)
      );
      const updatedTeamData = { ...teamData, members: filteredMembers };

      setTeamData(updatedTeamData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching team data:", error);
      setIsLoading(false);
    }
  };

  const handleChevronClick = () => {
    if (!expanded && !teamData) {
      fetchTeamData();
    }
    setExpanded(!expanded);
  };
  const getPlayerType = (discordTag, captain, subcaptains, staff) => {
    if (discordTag === captain) {
      return "captain";
    } else if (subcaptains.includes(discordTag)) {
      return "subcaptain";
    } else if (staff.includes(discordTag)) {
      return "staff";
    } else {
      return "player";
    }
  };

  const renderPlayerCards = () => {
    if (!teamData || !teamData.members) {
      return null;
    }
    const members = teamData.members.filter((member) =>
      teamData.players.includes(member.discord_tag)
    );
    const captain = teamData.captain && teamData.captain[0];
    const subcaptains = teamData.subcaptains;

    return members.map((member, index) => {
      const memberType = getPlayerType(
        member.discord_tag,
        captain,
        subcaptains,
        teamData.staff
      );

      return (
        <PlayerCard
          key={index}
          member={memberType}
          name={member.discord_tag}
          role={member.role}
          region={member.region}
          soloq_account={member.soloq_account__summonerName}
          elo={`${member.soloq_account__tier} ${member.soloq_account__rank}`}
          tier={member.soloq_account__tier}
          rank={member.soloq_account__rank}
          lp={member.soloq_account__leaguePoints}
          active={member.is_active}
          summoner={member.soloq_account__summonerName}
          user_id={member.user_id}
          avatar_id={member.avatar_id}
          coins={member.coins}
        />
      );
    });
  };
  const getRankImage = (rating) => {
    if (rating >= 10.0) return challenger;
    if (rating >= 9.0) return grandmaster;
    if (rating >= 8.0) return master;
    if (rating >= 7.0) return diamond;
    if (rating >= 6.0) return emerald;
    if (rating >= 5.0) return platinum;
    if (rating >= 4.0) return gold;
    if (rating >= 3.0) return silver;
    if (rating >= 2.0) return bronce;
    return iron;
  };

  function formatTime(timeString) {
    const parts = timeString.split(":");
    return parts[0] + ":" + parts[1];
  }

  function utcToMexTime(utcTimeString) {
    const now = new Date();
    const dateString = now.toISOString().split("T")[0];
    const completeUTCString = dateString + "T" + utcTimeString + "Z";

    const utcDate = new Date(completeUTCString);
    const options = {
      timeZone: "America/Mexico_City",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return new Intl.DateTimeFormat("en-US", options).format(utcDate);
  }

  function utcToArgentinaTime(utcTimeString) {
    const now = new Date();
    const dateString = now.toISOString().split("T")[0];
    const completeUTCString = dateString + "T" + utcTimeString + "Z";

    const utcDate = new Date(completeUTCString);
    const options = {
      timeZone: "America/Argentina/Buenos_Aires",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return new Intl.DateTimeFormat("en-US", options).format(utcDate);
  }

  function utcDateToArgentinaTime(utcDateString, utcTimeString) {
    const [year, month, day] = utcDateString.split("-");
    const [hour, minute] = utcTimeString.split(":");

    const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute));

    const utcTimestamp = utcDate.getTime();
    const argentinaTimestamp = utcTimestamp - 0 * 60 * 60 * 1000; 

    const argentinaDate = new Date(argentinaTimestamp);

    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "America/Argentina/Buenos_Aires",
    };
    return argentinaDate.toLocaleString("es-MX", options).split(",")[0];
  }

  function utcDateToMexicoTime(utcDateString, utcTimeString) {
    const [year, month, day] = utcDateString.split("-");
    const [hour, minute] = utcTimeString.split(":");

    const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute));

    const utcTimestamp = utcDate.getTime();
    const mexicoTimestamp = utcTimestamp - 3 * 60 * 60 * 1000;

    const mexicoDate = new Date(mexicoTimestamp);

    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "America/Argentina/Buenos_Aires",
    };

    return mexicoDate.toLocaleString("es-MX", options).split(",")[0];
  }

    //MEXICO DATE TO ARGENTINA
  function mexicoToArgentinaDate(mexicoDateString, mexicoTimeString) {
    const [mexicoYear, mexicoMonth, mexicoDay] = mexicoDateString.split("-");
    const [mexicoHour, mexicoMinute] = mexicoTimeString.split(":");
  
    const mexicoDate = new Date(
      mexicoYear,
      mexicoMonth - 1,
      mexicoDay,
      mexicoHour,
      mexicoMinute
    );
  
    mexicoDate.setHours(mexicoDate.getHours() + 3);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "America/Argentina/Buenos_Aires",
    };
  
    return mexicoDate.toLocaleString("es-AR", options).split(",")[0];
  }
  
  function mexicoToArgentinaTime(mexicoTimeString) {
    const now = new Date();
    const dateString = now.toISOString().split("T")[0];
    const completeMexicoString = dateString + "T" + mexicoTimeString + "Z";
  
    const mexicoDate = new Date(completeMexicoString);
  
    mexicoDate.setHours(mexicoDate.getHours() + 3); 

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "America/Argentina/Buenos_Aires",
    };
  
    return new Intl.DateTimeFormat("es-AR", options).format(mexicoDate);
  }
  function changeDateFormat(yearMonthDay) {
    const [year, month, day] = yearMonthDay.split("-");
    return `${day}/${month}/${year}`;
  }

  return (
    <div
      className="myGradient flex flex-col justify-between items-center py-2 pl-4 pr-4 rounded-md text-white
  text-xs sm:text-sm md:text-base transform transition-transform hover:border hover:border-yellow-500 relative"
    >
      <div className="flex justify-around w-full items-center pl-4 pr-2">
        <div className="flex flex-col gap-1 items-center">
          <img
            src={Images[scrim.team_image]}
            alt="Rank"
            className="inline w-12 sm:w-16 md:w-20"
          />
          <div
            onClick={() => goToTeamPage(scrim.team)}
            className="hover:underline cursor-pointer hover:text-rose-500 text-xs sm:text-sm md:text-base"
          >
            {scrim.team}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            src={getRankImage(scrim.average_rating)}
            alt="Rank"
            className="inline w-12 sm:w-16 md:w-20"
          />
          <div>{scrim.region}</div>
        </div>
        <div className="hidden sm:flex sm:flex-col">
          <span className="flex gap-1">
            {scrim.date}{" "}
            <Flag code="mex" width={20} />
          </span>
          <span className="flex gap-1">
            {mexicoToArgentinaDate(scrim.date, scrim.time)}{" "}
            <Flag code="arg" width={20} />
          </span>
        </div>
        <div className="flex-col hidden sm:flex">
          <span className="flex gap-1">
            {scrim.time} <Flag code="mex" width={20} />
          </span>
          <span className="flex gap-1">
            {mexicoToArgentinaTime(scrim.time)} <Flag code="arg" width={20} />
          </span>
        </div>
        <div className="flex flex-col sm:hidden">
          <span>{scrim.date}</span>
          <span className="flex gap-1">
            {scrim.time} <Flag code="mex" width={20} />
          </span>
          <span className="flex gap-1">
            {scrim.time} <Flag code="arg" width={20} />
          </span>
        </div>
        {isCaptain() && handleAcceptClick && (
          <div>
            <button
              className={
                scrim.team === userData.team_info.team_name
                  ? "bg-gray-500 text-white font-bold py-2 px-2 sm:px-4 rounded w-16 sm:w-24"
                  : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 sm:px-4 rounded w-16 sm:w-24"
              }
              onClick={() =>
                scrim.team !== userData.team_info.team_name &&
                handleAcceptClick(scrim)
              }
              disabled={scrim.team === userData.team_info.team_name}
            >
              {scrim.team === userData.team_info.team_name
                ? "Tu scrim"
                : "Invitar"}
            </button>
          </div>
        )}
      </div>
      <div onClick={handleChevronClick} className="vertical-bar"></div>
      <div className="flex justify-end w-full absolute bottom-2 right-2">
        {expanded ? (
          <FaChevronUp
            className="cursor-pointer hover:text-rose-500"
            onClick={handleChevronClick}
          />
        ) : (
          <FaChevronDown
            className="cursor-pointer hover:text-rose-500"
            onClick={handleChevronClick}
          />
        )}
      </div>

      {expanded && (
        <div className="mt-4 w-full flex flex-col gap-2 px-4">
          {isLoading ? (
            <CardLoader />
          ) : (
            <div className="flex flex-col gap-2">{renderPlayerCards()}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScrimCard;
