import React, { useState } from "react";
import { FaCoins } from "react-icons/fa";
import axios from "axios";
import Loader from "../../components/Loader"; 

export const ProductCard = ({
  id,
  title,
  description,
  price,
  image,
  endpoint,
  disabled,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const handleBuy = async () => {
    if (endpoint !== "" && !disabled) {
      try {
        setLoading(true);
        const response = await axios.post(endpoint);
        setModalContent(response.data.detail);
        setModalTitle("Compra Exitosa");
        setModalOpen(true);
      } catch (error) {
        setModalContent(
          error.response.data.detail || "Error al realizar la compra"
        );
        setModalTitle("Error al realizar la compra");
        setModalOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent("");
    setModalTitle("");
    window.location.reload();
  };

  return (
    <div
      className={`w-full sm:w-1/2 md:w-1/3 p-4 relative group transition duration-300 ${
        disabled ? "opacity-50 pointer-events-none" : "hover:border-yellow-500"
      }`}
    >
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      )}{" "}
      <div className="bg-gray-300 rounded-t-md border border-yellow-500 border-t-2 border-l-2 border-r-2 border-b-0">
        <div className="h-48 overflow-hidden">
          <img
            src={image}
            alt={title}
            className="w-full h-full p-4 object-contain"
          />
        </div>
      </div>
      <div className="bg-white text-center p-4 rounded-b-md border border-yellow-500 border-b-2 border-r-2 border-l-2 border-t-0 flex flex-col h-96">
        <p className="text-lg font-semibold mb-2">{title}</p>
        <div className="flex-grow flex flex-col justify-between">
          <div className="flex-shrink-0 justify-center text-center content-center">
            <p className="text-gray-600 flex items-center text-center justify-center ">
              <FaCoins className="text-yellow-500 mr-1" /> {price}
            </p>
            <div className="text-center mx-2 my-2">
              {description.split("\n").map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
          </div>

          <button
            disabled={disabled || loading}
            className="bg-yellow-500 text-white px-4 py-2 mt-4 rounded-md"
            onClick={handleBuy}
          >
            {loading ? "Cargando..." : "Comprar"}
          </button>
        </div>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
            <h3
              className={`font-bold text-lg ${
                modalTitle.includes("Error") ? "text-red-500" : ""
              }`}
            >
              {modalTitle}
            </h3>
            <p>{modalContent}</p>
            <button
              onClick={closeModal}
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
