import React, { useState, useEffect } from "react";
import Axios from "axios";
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import { TbSwords } from "react-icons/tb";
import Button from "../../components/Button";
import MyScrimCard from "./MyScrimCard";
import CardsHeading from "../../components/CardsHeading";
import Loader from "../../components/Loader";
const MyScrims = ({ userData }) => {
  const [scrims, setScrims] = useState([]);
  const [status, setStatus] = useState("PENDIENTE");
  const [width, setWidth] = useState(window.innerWidth);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    const fetchScrims = async () => {
      try {
        const response = await Axios.get(`/api/my-scrims/${status}/`);
        setScrims(response.data);
        setIsFetching(false);
      } catch (error) {
        console.error("Error fetching scrims:", error);
        setIsFetching(false);
      }
    };

    fetchScrims();
  }, [status]);

  const handleCancel = (scrimId) => {
    setIsCancelling((prev) => ({ ...prev, [scrimId]: true }));

    Axios.post(`/api/cancel-scrim-request/`, { id: scrimId })
      .then((response) => {
        setIsCancelling((prev) => ({ ...prev, [scrimId]: false }));
        setScrims((prevScrims) =>
          prevScrims.filter((scrim) => scrim.id !== scrimId)
        );
      })
      .catch((error) => {
        console.error("Error canceling scrim:", error);
        setIsCancelling((prev) => ({ ...prev, [scrimId]: false }));
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isCaptain = () =>
    userData &&
    userData.team_info &&
    (userData.team_info.is_captain || userData.team_info.is_subcaptain);

  const getTitles = () => {
    if (width < 640) {
      if (isCaptain()) {
        return ["Equipo", "Elo", "Fecha", "Accion"];
      }
      return ["Equipo", "Elo", "Fecha"];
    }
    return isCaptain()
      ? ["Equipo", "Elo", "Fecha", "Hora", "Accion"]
      : ["Equipo", "Elo", "Fecha", "Hora"];
  };

  return (
    <Container>
      {isFetching || userData === undefined ? (
        <div className="flex justify-center items-center h-screen bg-black bg-opacity-50 fixed top-0 left-0 w-full z-50">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-4">
          <TitleContainer
            text={"Mis Scrims"}
            icon={<TbSwords />}
            color="--primary-color"
            flex="start"
          />
          <div className="flex gap-2 items-center">
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="bg-white shadow rounded-sm p-2 border text-xs"
            >
              <option value="PENDIENTE">Pendiente</option>
              <option value="ACEPTADO">Aceptado</option>
              <option value="CANCELADO">Cancelado</option>
            </select>
            {userData &&
              userData.in_team &&
              userData.team_info &&
              (userData.team_info.is_captain ||
                userData.team_info.is_subcaptain) && (
                <div className="flex flex-col gap-2">
                  <Button link="/league/scrims/create">Crear Scrim</Button>
                </div>
              )}
          </div>
          <div className="scrimList mt-2">
            <div className="flex flex-col gap-2">
              <CardsHeading titles={getTitles()} />
              {scrims.map((scrim, index) => (
                <div key={index}>
                  <MyScrimCard
                    scrim={scrim}
                    handleCancelClick={() => handleCancel(scrim.id)}
                    isCaptain={isCaptain}
                    isCancelling={isCancelling[scrim.id] || false}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default MyScrims;
