import React, { useState, useEffect, useRef } from "react";
import Container from "../Container";
import Logo from "./Logo";
import User from "./User";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineClose } from "react-icons/md";
import Button from "../Button";
import NavLink from "./NavLink";
import Loader2 from "../Loader2";

const Navbar = ({ userData, isUserLoggedIn, user, setUserData }) => {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [loading, setLoading] = useState(true);

  const mobileMenuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleMobileMenuItemClick = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {


    const loginTimer = setTimeout(() => {
      if (!isUserLoggedIn && loading) {
        setLoading(false);
        setShowLoginButton(true);
      }
    }, 3500);

    return () => {
      clearTimeout(loginTimer);
    };
  }, [isUserLoggedIn, loading]);

  useEffect(() => {
    if (user !== null) {
      setLoading(false);
    }
  }, [userData,user]);


  return (
    <nav className="bg-secondary py-2 text-white relative z-20">
      <Container>
        <div className="flex justify-between items-center">
          <div className="sm:hidden" onClick={() => setIsMobileMenuOpen(true)}>
            <RxHamburgerMenu size={16} />
          </div>
          <div className="hidden sm:block flex-auto ">
            <Logo />
          </div>
          <div className="hidden sm:flex justify-center gap-2 sm:gap-3 md:gap-4 lg:gap-5 xl:gap-6 flex-auto">
            <NavLink link="/league/equipos">Equipos</NavLink>
            <NavLink link="/league/free-agents">Jugadores</NavLink>
            <NavLink link="/league/transferencias">Transferencias</NavLink>
            <NavLink link="/league/scrims">Scrim Wars</NavLink>
            <NavLink link="/shop">Tienda</NavLink>
            <NavLink link="/tournaments">Torneos</NavLink>
          </div>
          <div className="flex items-center justify-end gap-2 flex-auto w-24">
            {loading ? (
              <Loader2 />
            ) : (
              <>
                {user !== null && userData !== null ? (
                  user.has_profile ? (
                    <div>
                      <User
                        data={userData}
                        user={user}
                        setUserData={setUserData}
                        coins={user.user_profile.coins}
                      />
                    </div>
                  ) : (
                    <div className="flex gap-2 items-center">
                      <Button link="/login">Log in</Button>2
                    </div>
                  )
                ) : (
                  <div className="flex gap-2 items-center">
                    {showLoginButton ? (
                      <Button link="/login">Log in</Button>
                    ) : (
                      <Loader2 />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Container>

      {isMobileMenuOpen && (
        <div
          ref={mobileMenuRef}
          className={`absolute top-0 left-0 w-1/2 h-full bg-secondary z-20 transform transition-transform duration-300 lg:hidden`}
        >
          <button onClick={() => setIsMobileMenuOpen(false)} className="p-2">
            <MdOutlineClose />
          </button>
          <ul className="flex flex-col items-start px-4 gap-1 bg-secondary p-2">
            <li onClick={handleMobileMenuItemClick}>
              <NavLink link="/">Inicio</NavLink>
            </li>
            <li onClick={handleMobileMenuItemClick}>
              <NavLink link="/league/equipos">Equipos</NavLink>
            </li>
            <li onClick={handleMobileMenuItemClick}>
              <NavLink link="/league/free-agents">Free agents</NavLink>
            </li>
            <li onClick={handleMobileMenuItemClick}>
              <NavLink link="/league/transferencias">Transferencias</NavLink>
            </li>
            <li onClick={handleMobileMenuItemClick}>
              <NavLink link="/league/scrims">Scrims Wars</NavLink>
            </li>
            <li onClick={handleMobileMenuItemClick}>
            <NavLink link="/shop">Tienda</NavLink>
            </li>
            <li onClick={handleMobileMenuItemClick}>
            <NavLink link="/tournaments">Torneos</NavLink>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
