import React from "react";
import {
  RiTicket2Line,
  RiShieldStarLine,
  RiCheckboxCircleLine,
  RiArrowDownSLine,
} from "react-icons/ri";

const summonerSpellList = [
  {
    name: "Ghost",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerHaste.png",
  },
  {
    name: "Heal",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerHeal.png",
  },
  {
    name: "Exhaust",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerExhaust.png",
  },
  {
    name: "Ignite",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerDot.png",
  },
  {
    name: "Barrier",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerBarrier.png",
  },
];

const UserDetailsTournament = ({
  tournamentTickets,
  targetChampionBan,
  rulesetTier1,
  rulesetTier2,
  rulesetTier3,
  lowTierSpellBan,
  highTierSpellBan,
  canceledRule,
}) => {
  return (
    <div className="user-details bg-gray-300 p-4 rounded-md border-2 border-yellow-500">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 justify-center place-items-center">
        <div className="details-row flex items-center">
          <RiTicket2Line className="icon" />
          <span className="title ml-2 font-bold">Join Tickets:</span>
          <span className="value ml-2">{tournamentTickets}</span>
        </div>
        <div className="details-row flex items-center">
          <RiShieldStarLine className="icon" />
          <span className="title ml-2 font-bold">Ban Champion Ticket:</span>
          <span className="value ml-2">{targetChampionBan}</span>
        </div>
        <div className="details-row flex items-center">
          <RiCheckboxCircleLine className="icon" />
          <span className="title ml-2 font-bold">Tier 1 Rule:</span>
          <span className="value ml-2">{rulesetTier1}</span>
        </div>
        <div className="details-row flex items-center">
          <RiCheckboxCircleLine className="icon" />
          <span className="title ml-2 font-bold">Tier 2 Rule:</span>
          <span className="value ml-2">{rulesetTier2}</span>
        </div>
        <div className="details-row flex items-center">
          <RiCheckboxCircleLine className="icon" />
          <span className="title ml-2 font-bold">Tier 3 Rule:</span>
          <span className="value ml-2">{rulesetTier3}</span>
        </div>
        <div className="details-row flex items-center">
          <RiArrowDownSLine className="icon" />
          <span className="title ml-2 font-bold">Ban Low Tier Spell:</span>
          <span className="value ml-2">{lowTierSpellBan}</span>
        </div>
        <div className="details-row flex items-center">
          <RiArrowDownSLine className="icon" />
          <span className="title ml-2 font-bold">Ban High Tier Spell:</span>
          <span className="value ml-2">{highTierSpellBan}</span>
        </div>
        <div className="details-row flex items-center">
          <RiArrowDownSLine className="icon" />
          <span className="title ml-2 font-bold">Cancel Rule:</span>
          <span className="value ml-2">{canceledRule}</span>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 justify-center mt-4">
        <div className="summoner-spells-col">
          <h2 className="font-bold mb-2 text-center">High Tier Summoner Spells</h2>
          <div className="summoner-spells flex justify-center gap-3">
            {summonerSpellList
              .filter(
                (spell) => spell.name === "Ignite" || spell.name === "Exhaust"
              )
              .map((spell) => (
                <img
                  key={spell.name}
                  src={spell.imageUrl}
                  alt={spell.name}
                  className="summoner-spell-img rounded-lg w-5"
                />
              ))}
          </div>
        </div>
        <div className="summoner-spells-col">
          <h2 className="font-bold mb-2 text-center">Low Tier Summoner Spells</h2>
          <div className="summoner-spells flex justify-center gap-3">
            {summonerSpellList
              .filter(
                (spell) =>
                  spell.name === "Ghost" ||
                  spell.name === "Heal" ||
                  spell.name === "Barrier"
              )
              .map((spell) => (
                <img
                  key={spell.name}
                  src={spell.imageUrl}
                  alt={spell.name}
                  className="summoner-spell-img rounded-lg w-5"
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsTournament;
