import React, { useState } from "react";
import Container from "../../components/Container";
import { ProductCard } from "./ProductCard"; 
import { IoInformationCircleOutline } from "react-icons/io5";
import DiscordRoleUpgradeImage from '../../assets/shop/Discord_role_upgrade.png';
import RulesetImage from '../../assets/shop/ruleset3.png';
import Ticket from '../../assets/shop/Ticket_Clash.png';

const Index = () => {
  const products = [
    {
      id: 2,
      title: "Ticket de Torneo",
      price: 2500,
      image:
      Ticket,
      endpoint: "/shop/buy/tournament-ticket/",
      disabled: false,
      description:
        "El ticket de torneo es para unirse a un torneo que no ha iniciado, dependiendo el torneo necesitarás más de un ticket para poder unirte (Objeto Consumible).",
    },
    {
      id: 3,
      title: "Ruleset Nivel 1",
      price: 2500,
      image:
      RulesetImage,

      endpoint: "/shop/buy/rule-set-tier-3/",
      disabled: false,
      description: `El ticket de reglas nivel 1 se intercambia por un ticket consumible que otorga uno de los siguientes beneficios:\n1.- Banear un campeón aleatorio.\n2.- Banear un hechizo de invocador de bajo nivel (Heal/Barrier/Ghost) al azar.`,
    },
    {
      id: 4,
      title: "Ruleset Nivel 2",
      price: 4000,
      image:
      RulesetImage,
      endpoint: "/shop/buy/rule-set-tier-2/",
      disabled: false,
      description: `El ticket de reglas nivel 2 se intercambia por un ticket consumible que otorga uno de los siguientes beneficios:\n1.- Banear un campeón a tu elección.\n2.- Banear un hechizo de invocador de bajo nivel (Heal/Barrier/Ghost) a tu elección.\n3.- Banear un hechizo al azar de nivel alto (Ignite, Extenuar).`,
    },
    {
      id: 5,
      title: "Ruleset Nivel 3",
      price: 10000,
      image:
      RulesetImage,
      endpoint: "/shop/buy/rule-set-tier-1/",
      disabled: false,
      description: `El ticket nivel 3 se intercambia por un ticket consumible que otorga uno de los siguientes beneficios:\n1.- Cancelar una regla del torneo.\n2.- Banear un campeón a tu elección.\n3.- Banear un hechizo de invocador de alto nivel (Ignite, Extenuar, Teleport) a tu elección.\n4.- Banear un ítem de alto nivel al azar (3000+ de costo ingame).`,
    },
    {
      id: 1,
      title: "Role Upgrade",
      price: "???",
      image:
      DiscordRoleUpgradeImage,
      endpoint: "",
      disabled: true,
      description: "Conseguir mejoras de rol en el servidor de Discord.",
    },
  ];
  const [showPopover, setShowPopover] = useState(false);
  return (
    <div className="flex items-start justify-center">
      <Container className="flex flex-col items-center justify-center mt-20 relative">
        <div className="absolute top-20 right-0 mt-4 mr-4 text-white">
          <button
            className="flex items-center text-xs font-semibold bg-gray-800 rounded-full px-3 py-1"
            onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}
          >
            <IoInformationCircleOutline className="mr-1" />
            ¿Cómo conseguir monedas?
          </button>
          <div
            className={`${
              showPopover ? "block" : "hidden"
            } bg-gray-800 rounded-lg p-4 absolute top-10 right-0 z-10 w-48 text-xs`}
          >
            <p>Jugando scrims</p>
            <p>Participando en el discord</p>
            <p>Haciendo donaciones</p>
          </div>
        </div>
        <div className="text-4xl font-bold text-white text-center mb-8 mt-8">
          Shop
        </div>
        <div className="flex flex-wrap justify-center">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              id={product.id}
              title={product.title}
              description={product.description}
              price={product.price}
              image={product.image}
              endpoint={product.endpoint}
              disabled={product.disabled}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Index;