import React, { useState, useEffect } from "react";
import Axios from "axios";
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import { LuSwords } from "react-icons/lu";
import ScrimList from "./ScrimList";
import ScrimCreate from "./ScrimCreate";
import Loader from "../../components/Loader";

const Index = ({ userData }) => {
  const [scrimList, setScrimList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchScrims = async () => {
      try {
        const response = await Axios.get("/api/scrims");
        setScrimList(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching scrims:", error);
        setIsLoading(false);
      }
    };

    fetchScrims();
  }, []);

  const isInTeam =
    userData && userData.in_team !== undefined && userData.in_team;
  const isCaptainOrSubCaptain =
    userData &&
    userData.team_info &&
    (userData.team_info.is_captain || userData.team_info.is_subcaptain);

  return (
    <Container>
      <div className="flex flex-col gap-2 mt-4">
        {isLoading ? (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
            <Loader />
          </div>
        ) : null}
        {isInTeam && isCaptainOrSubCaptain && (
          <div className="flex flex-col gap-2">
            <TitleContainer
              text={"Crear un Scrim War"}
              icon={<LuSwords />}
              color="--fifth-color"
              flex="start"
            />
            <ScrimCreate />
          </div>
        )}

        <TitleContainer
          text={
            "Scrim Wars: Partidas personalizadas 5 vs 5 entre dos equipos, que simula un escenario competitivo"
          }
          icon={<LuSwords />}
          color="--primary-color"
          flex="start"
        />
        <ScrimList scrims={scrimList} userData={userData} />
      </div>
    </Container>
  );
};

export default Index;
