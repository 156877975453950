import React from "react";

const MessageModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
        <h3 className="font-bold text-lg">Scrims</h3>
        <p>{children}</p>
        <button
          onClick={onClose}
          className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default MessageModal;
