import React, { useState, useEffect } from "react";
import Axios from "axios";
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import { TbSwords } from "react-icons/tb";
import Loader from "../../components/Loader";
import ScrimMatchCard from "./ScrimMatchCard";
import CardsHeading from "../../components/CardsHeading";
const ScrimMatches = ({ userData }) => {
  const [matches, setMatches] = useState([]);
  const [filterStatus, setFilterStatus] = useState("PENDIENTE");
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isWorking, setIsWorking] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    Axios.get("/api/my-scrims-schedule")
      .then((response) => {
        setMatches(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        console.error("Error fetching scrim matches:", error);
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isCaptainOrSubcaptain = () =>
    userData &&
    userData.team_info &&
    (userData.team_info.is_captain || userData.team_info.is_subcaptain);

  const handleCancel = (matchId) => {
    setIsWorking(true);
    setIsLoading(true);
    Axios.post("/api/cancel-scrim-match/", { id: matchId })
      .then(() => {
        setMatches((prev) => prev.filter((match) => match.id !== matchId));
        setIsWorking(false);
      })
      .catch((error) => {
        console.error("Error canceling scrim match:", error);
        setIsWorking(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getTitles = () => {
    if (width < 640) {
      if (isCaptainOrSubcaptain()) {
        return ["Fecha", "Equipo 1", "Equipo 2", "Accion"];
      }
      return ["Fecha", "Equipo 1", "Equipo 2"];
    }
    return isCaptainOrSubcaptain()
      ? ["Fecha", "Hora", "Equipo 1", "Equipo 2", "Accion"]
      : ["Fecha", "Hora", "Equipo 1", "Equipo 2"];
  };
  return (
    <Container>
      {isLoading || isFetching || userData === undefined ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-4">
          <TitleContainer
            text={"Scrims agendados"}
            icon={<TbSwords />}
            color="--primary-color"
            flex="start"
          />
          <div>
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="bg-white shadow rounded-sm p-1 border text-xs"
            >
              <option value="PENDIENTE">Pendiente</option>
              <option value="ACEPTADO">Aceptado</option>
              <option value="CANCELADO">Cancelado</option>
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <CardsHeading titles={getTitles()} />
            {matches
              .filter((match) => match.status === filterStatus)
              .map((match, index) => (
                <div key={index}>
                  <ScrimMatchCard
                    scrim={match}
                    handleCancelClick={() => handleCancel(match.id)}
                    isCaptain={isCaptainOrSubcaptain}
                    isWorking={isWorking}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default ScrimMatches;
