import React, { useState, useEffect } from "react";
import { BsPersonCircle, BsFillBellFill } from "react-icons/bs";
import { RiTeamFill } from "react-icons/ri";
import { useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import Notifications from "../Notifications";
import NotificationsTeam from "../NotificationsTeam";
import { TbCoins } from "react-icons/tb";
const User = ({ data, user, setUserData, coins }) => {
  const id = data.id;
  const avatar = data.avatar;
  const image = `https://cdn.discordapp.com/avatars/${id}/${avatar}.png`;

  const [hasImageError, setHasImageError] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [hasPendingInvites, setHasPendingInvites] = useState(false);
  const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);
  const [hasUnreadNotification, setHasUnreadNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const [isTeamInvitationsVisible, setIsTeamInvitationsVisible] =
    useState(false);
  const [teamInvitations, setTeamInvitations] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !isDropdownVisible &&
        !isNotificationsVisible &&
        !isTeamInvitationsVisible
      )
        return;

      setIsDropdownVisible(false);
      setIsNotificationsVisible(false);
      setIsTeamInvitationsVisible(false);
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownVisible, isNotificationsVisible, isTeamInvitationsVisible]);

  useEffect(() => {
    if (user && user.has_profile && data.in_team) {
      axios
        .get("/api/my-scrims-notifications/")
        .then((response) => {
          setNotifications(response.data);
          setHasUnreadNotification(
            response.data.some((notification) => !notification.is_read)
          );
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    }
  }, [user, data.in_team, location.pathname]);

  const navigate = useNavigate();

  const handleNotificationsUpdate = (updatedNotifications) => {
    setNotifications(updatedNotifications);
    setHasUnreadNotification(
      updatedNotifications.some((notification) => !notification.is_read)
    );
  };

  const handleImageError = () => {
    setHasImageError(true);
  };
  const handleBellClick = (event) => {
    event.stopPropagation();
    toggleNotifications();
  };

  const handleUserMenuClick = (event) => {
    event.stopPropagation();
    toggleDropdown();
  };

  const handleTeamMenuClick = (event) => {
    event.stopPropagation();
    toggleTeamInvitations();
  };
  const toggleDropdown = () => {
    setIsDropdownVisible((prevState) => !prevState);
    setIsNotificationsVisible(false);
    setIsTeamInvitationsVisible(false);
  };

  const toggleNotifications = () => {
    setIsNotificationsVisible((prev) => !prev);
    setIsDropdownVisible(false);
    setIsTeamInvitationsVisible(false);
  };

  useEffect(() => {
    const fetchPendingInvitations = async () => {
      try {
        const response = await axios.get("/team-invite/pending/me/");
        if (response.data && response.data.length > 0) {
          setHasPendingInvites(true);
          setTeamInvitations(response.data);
        }
      } catch (error) {
        console.error("Error fetching pending invitations", error);
      }
    };

    fetchPendingInvitations();
  }, [location.pathname]);

  const toggleTeamInvitations = () => {
    setIsTeamInvitationsVisible((prev) => !prev);
    setIsDropdownVisible(false);
    setIsNotificationsVisible(false);
  };

  const handleLogout = () => {
    window.location.href = '/oauth2/logout';
  };

  return (
    <div className="flex items-center justify-center gap-1 relative">
      {user && user.has_profile && data.in_team && (
        <div className="mr-2 cursor-pointer relative" onClick={handleBellClick}>
          <BsFillBellFill size={20} />
          {hasUnreadNotification && (
            <span className="absolute top-0 left-0 bg-red-500 rounded-full w-2 h-2"></span>
          )}
        </div>
      )}
      {user && user.has_profile && !data.in_team && (
        <div
          className="mr-2 cursor-pointer relative"
          onClick={handleTeamMenuClick}
        >
          <RiTeamFill size={20} />
        </div>
      )}
      {user && user.has_profile && (
        <div className="flex gap-1 text-sm items-center relative top-0.5">
          <TbCoins size={20} />
          {coins}
        </div>
      )}

      {isNotificationsVisible && user && user.has_profile && data.in_team && (
        <div
          className="absolute top-0 mt-8 right-0 z-10 max-w-md"
          onClick={(e) => e.stopPropagation()}
        >
          <Notifications
            notifications={notifications}
            onNotificationsUpdate={handleNotificationsUpdate}
          />
        </div>
      )}
      {isTeamInvitationsVisible && user && user.has_profile && (
        <div
          className="absolute top-0 mt-8 right-0 z-10 max-w-md"
          onClick={(e) => e.stopPropagation()}
        >
          <NotificationsTeam notifications={teamInvitations} />
        </div>
      )}

      {data.discord_tag}
      <div
        className="rounded-full cursor-pointer"
        onClick={handleUserMenuClick}
      >
        {hasImageError ? (
          <div className="rounded-full">
            <BsPersonCircle size={40} />
          </div>
        ) : (
          <img
            src={image}
            alt="User Avatar"
            onError={handleImageError}
            width={40}
            height={40}
            className="rounded-full"
          />
        )}
      </div>
      {isDropdownVisible && (
        <div
          style={{ width: "200px" }}
          className="absolute top-0 mt-8 right-0 bg-fifth border rounded shadow-lg text-black z-10 max-w-md"
          onClick={(e) => e.stopPropagation()}
        >
          <ul className="text-white">
            <li
              className="px-4 py-2 cursor-pointer fill-hover"
              onClick={() => navigate("/profile")}
            >
              <div className="text-xs">Mi perfil</div>
            </li>

            {user.has_profile && user.user_profile.status === "EN_EQUIPO" && (
              <li
                className="px-4 py-2 cursor-pointer fill-hover"
                onClick={() =>
                  navigate(`/league/equipos/${data.team_info.team_name}`)
                }
              >
                <div className="text-xs">Mi Equipo</div>
              </li>
            )}
            {user.has_profile && (
              <li
                className="px-4 py-2 cursor-pointer fill-hover"
                onClick={() => navigate(`/league/bets`)}
              >
                <div className="text-xs">Mis Predicciones</div>
              </li>
            )}
            {user.has_profile && user.user_profile.status !== "EN_EQUIPO" && (
              <li
                className="px-4 py-2 cursor-pointer relative fill-hover"
                onClick={() => navigate("/invitations")}
              >
                <div className="text-xs inline-flex items-center">
                  Invitaciones
                  {hasPendingInvites && (
                    <span className="ml-2 bg-red-500 rounded-full w-2 h-2 inline-block"></span>
                  )}
                </div>
              </li>
            )}

            {user.has_profile && user.user_profile.status === "EN_EQUIPO" && (
              <li
                className="px-4 py-2 cursor-pointer fill-hover"
                onClick={() => navigate("/league/scrims/myteam")}
              >
                <div className="text-xs">Mis Scrims</div>
              </li>
            )}

            {user.has_profile && user.user_profile.status === "EN_EQUIPO" && (
              <li
                className="px-4 py-2 cursor-pointer fill-hover"
                onClick={() => navigate("/league/scrims/matches")}
              >
                <div className="text-xs">Scrims agendados</div>
              </li>
            )}

            {user.has_profile && user.user_profile.status === "EN_EQUIPO" && (
              <li
                className="px-4 py-2 cursor-pointer fill-hover"
                onClick={() => navigate("/league/scrims/invitations-received")}
              >
                <div className="text-xs">Solicitudes de scrim recibidas</div>
              </li>
            )}

            {user.has_profile && user.user_profile.status === "EN_EQUIPO" && (
              <li
                className="px-4 py-2 cursor-pointer fill-hover"
                onClick={() => navigate("/league/scrims/invitations-sent")}
              >
                <div className="text-xs">Solicitudes de scrim enviadas</div>
              </li>
            )}

            <li
              className="px-4 py-2 cursor-pointer fill-hover"
              onClick={handleLogout}
            >
              <a
                href="/oauth2/logout"
                className="text-xs"
                onClick={(e) => e.preventDefault()}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default User;
