import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/index";
import Teams from "./pages/teams/index";
import Login from "./pages/login/index";
import LinkSoloQAccount from "./pages/profile/LinkSoloQAccount";
import Profile from "./pages/profile/index";
import CreateTeam from "./pages/teams/CreateTeam";
import Free_agent from "./pages/free_agents/index";
import Transferencias from "./pages/transfers/index";
import TeamPage from "./pages/teams/TeamPage";
import Invitations from "./pages/invitations";
import Scrims from "./pages/scrims/index";
import NewScrim from "./pages/scrims/CreatePage";
import MyScrims from "./pages/scrims/MyScrims";
import ScrimInvs from "./pages/scrims/ScrimInvs";
import ScrimsSent from "./pages/scrims/ScrimsSent";
import Matches from "./pages/scrims/ScrimMatches";
import Bets from "./pages/bets/index";
import Shop from "./pages/shop/index";
import Tournaments from "./pages/tournaments/index";

import SucessRiotLink from "./pages/riot_link/RiotLinkSuccess";
import FailedRiotLink from "./pages/riot_link/RiotLinkFailed";

import ToS from "./pages/tos/index";
import Privacy from "./pages/privacy/index";


import icon from "./assets/logo.webp";
import { useLocation } from "react-router-dom";
function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [user, setUser] = useState(null);
  const location = useLocation(); 

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", icon);
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get("/api/check-session/");
        if (response.data.isAuthenticated === true) {
          setIsUserLoggedIn(true);
          const userResponse = await axios.get("/auth/user");
          setUserData(userResponse.data);
        }
      } catch (error) {
        console.error("There was an error checking the session!", error);
      }
    };
    checkSession();
  }, [location.pathname]);

  useEffect(() => {
    if (isUserLoggedIn) {
      axios.get("/api/check_user_profile/")
        .then((response) => {
          setUser(response.data); 
        })
        .catch((error) => console.error("Error fetching user profile:", error));
    }
  }, [isUserLoggedIn, location.pathname]);
  



  return (
    <>
      <Navbar userData={userData} isUserLoggedIn={isUserLoggedIn} user={user} setUserData={setUser} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/league/equipos"
          element={<Teams isUserLoggedIn={isUserLoggedIn} />}
        />
        <Route
          path="/league/equipos/:teamName"
          element={
            <TeamPage
              user={user}
              isUserLoggedIn={isUserLoggedIn}
              userData={userData}
            />
          }
        />
        <Route path="/league/new-team" element={<CreateTeam />} />
        <Route path="/linkLeagueAccount" element={<LinkSoloQAccount />} />
        <Route path="/profile" element={<Profile userData={userData} />} />
        <Route path="/league/bets" element={<Bets />} />
        <Route path="/invitations" element={<Invitations />} />
        <Route
          path="league/free-agents"
          element={<Free_agent userData={userData} user={user} />}
        />
        <Route path="league/transferencias" element={<Transferencias />} />
        <Route path="league/scrims" element={<Scrims userData={userData} />} />
        <Route
          path="league/scrims/matches"
          element={<Matches userData={userData} />}
        />
        <Route
          path="league/scrims/create"
          element={<NewScrim userData={userData} />}
        />
        <Route
          path="league/scrims/myteam"
          element={<MyScrims userData={userData} />}
        />
        <Route
          path="league/scrims/invitations-received"
          element={<ScrimInvs userData={userData} />}
        />
        <Route
          path="league/scrims/invitations-sent"
          element={<ScrimsSent userData={userData} />}
        />
        <Route path="/shop"   element={<Shop />} />

        <Route path="/tournaments" element={<Tournaments user={user} />} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/ToS" element={<ToS/>} />

        <Route path="/profile/riot-link/sucess" element={<SucessRiotLink/>} />
        <Route path="/profile/riot-link/failed" element={<FailedRiotLink/>} />

        

      </Routes>
    </>
  );
}

export default App;
