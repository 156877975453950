import Axios from "axios";
import React, { useEffect, useState } from "react";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { MdInsertInvitation } from "react-icons/md";
import InvitationList from "./InvitationList";
import Loader from "../../components/Loader";

const Index = () => {
  const [invitations, setInvitations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null); // Nuevo estado para el contenido del modal

  const fetchInvitations = () => {
    Axios.get("/team-invite/pending/me/")
      .then((response) => {
        setInvitations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching invitations:", error);
      });
  };

  useEffect(() => {
    fetchInvitations();
  }, []);

  const handleAccept = (invitationId) => {
    setIsLoading(true);

    Axios.post("/team-invite/accept/", {
      invite_id: invitationId,
    })
      .then((response) => {
        setModalContent(response.data.detail);
        setShowModal(true);
        fetchInvitations();
      })
      .catch((error) => {
        console.error("Error accepting invitation:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleReject = (invitationId) => {
    setIsLoading(true);

    Axios.post("/team-invite/reject/", {
      invite_id: invitationId,
    })
      .then((response) => {
        setModalContent(response.data.detail);
        setShowModal(true);
        fetchInvitations();
      })
      .catch((error) => {
        console.error("Error rejecting invitation:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Loader />
        </div>
      )}
      <div className="flex flex-col gap-2 mt-4">
        <TitleContainer
          text={"Invitaciones para equipos"}
          icon={<MdInsertInvitation />}
          color="--primary-color"
          flex="end"
        />
        <InvitationList
          list={invitations}
          onAccept={handleAccept}
          onReject={handleReject}
        />
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
            <h3 className="font-bold text-lg">Invitación de equipo</h3>
            <p>{modalContent}</p>
            <button
              onClick={() => setShowModal(false)}
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Index;
