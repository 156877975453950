import React from "react";
import { Paragraph } from "./Paragraph";
import { useNavigate } from 'react-router-dom';

const TitleContainer = ({ text, icon, color, flex, link }) => {
    const navigate = useNavigate();

    const handleClick = () => {
      if (link) {
        navigate(link);
      }
    };

    const style = {
        justifyContent: flex === 'end' ? 'flex-end' : 'flex-start', 
        backgroundColor: color.startsWith('--') ? `var(${color})` : color,
        padding: '.5em',
        borderRadius: '0.25em',
        width: '100%',
        display: 'flex',
        cursor: link ? 'pointer' : 'default'
    };

    return (
        <div style={style} onClick={handleClick} >
            <div 
            className="py-2 px-4 items-center"
            style={{ display: 'flex', gap: '0.5em', color: 'white' }}>
                {icon}
                <Paragraph white>{text}</Paragraph>
            </div>
        </div>
    );
};

export default TitleContainer;
