import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";
import TeamCard from "./TeamCard";
const TeamList = ({ userProfile2, isUserLoggedIn }) => {
  const [teams, setTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [region, setRegion] = useState("ALL");
  const [filteredTeams, setFilteredTeams] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); 

  useEffect(() => {
    axios
      .get("/api/teams/")
      .then((response) => {
        setTeams(response.data);
        setLoading(false); 
      })
      .catch((error) => {
        console.error("Error al obtener equipos:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let result = teams;
    if (searchTerm) {
      result = result.filter((team) =>
        team.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (region !== "ALL") {
      result = result.filter((team) => team.region === region);
    }

    setFilteredTeams(result);
  }, [searchTerm, region, teams]);


  return (
    <>
      {loading ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <div className="filter-section flex gap-1 text-white">
            <input
              className="bg-transparent border p-1"
              type="text"
              placeholder="Buscar Equipo"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              className="bg-transparent border"
            >
              <option className="text-black" value="ALL">
                All Regions
              </option>
              <option className="text-black" value="LAN">
                LAN
              </option>
              <option className="text-black" value="LAS">
                LAS
              </option>
            </select>
          </div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
            {filteredTeams.map((team, index) => (
              <div key={index}>
                <TeamCard
                  name={team.name}
                  image={team.image}
                  open={team.accepting_requests}
                  region={team.region}
                  captain={team.captain_name}
                  captain_id={team.captain_id}
                  captain_avatar={team.captain_avatar}
                  isUserLoggedIn={isUserLoggedIn}
                  userProfile2={userProfile2}
                  setShowModal={setShowModal}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {showModal === "login" && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-100 p-4 rounded-md w-1/2 text-center">
            <p>Necesitas estar conectado para ver rosters.</p>
            <div className="flex gap-4 justify-center mt-4">
              <button
                className="bg-gray-300 p-2 rounded"
                onClick={() => setShowModal(null)}
              >
                Cerrar
              </button>
              <button
                className="bg-blue-500 text-white p-2 rounded"
                onClick={() => navigate("/login")}
              >
                Conectarse
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal === "profile" && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-100 p-4 rounded-md w-1/2 text-center">
            <p>Necesitas crear un perfil primero para ver rosters.</p>
            <div className="flex gap-4 justify-center mt-4">
              <button
                className="bg-gray-300 p-2 rounded"
                onClick={() => setShowModal(null)}
              >
                Cerrar
              </button>
              <button
                className="bg-blue-500 text-white p-2 rounded"
                onClick={() => navigate("/profile")}
              >
                Mi perfil
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamList;
