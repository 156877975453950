import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import WorldFlags from "react-world-flags";

const CountryFlagSelector = ({ user_country, onCountrySelect }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const countries = [
    { code: "AR", name: "Argentina" },
    { code: "BO", name: "Bolivia" },
    { code: "BR", name: "Brazil" },
    { code: "CL", name: "Chile" },
    { code: "CO", name: "Colombia" },
    { code: "CR", name: "Costa Rica" },
    { code: "CU", name: "Cuba" },
    { code: "DO", name: "Dominican Republic" },
    { code: "EC", name: "Ecuador" },
    { code: "SV", name: "El Salvador" },
    { code: "GT", name: "Guatemala" },
    { code: "HN", name: "Honduras" },
    { code: "MX", name: "Mexico" },
    { code: "NI", name: "Nicaragua" },
    { code: "PA", name: "Panama" },
    { code: "PY", name: "Paraguay" },
    { code: "PE", name: "Peru" },
    { code: "PR", name: "Puerto Rico" },
    { code: "UY", name: "Uruguay" },
    { code: "VE", name: "Venezuela" },
  ];

  useEffect(() => {
    if (user_country) {
      const country = countries.find(c => c.code === user_country);
      if (country) {
        setSelectedCountry(country);
      }
    }
  }, [user_country]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country);
    onCountrySelect(country.code);
    closeModal();
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className="w-16 h-16 border border-yellow-500 rounded-full overflow-hidden cursor-pointer"
        onClick={openModal}
        style={{ backgroundColor: "bg-fifth" }}
      >
        {selectedCountry ? (
          <WorldFlags
            code={selectedCountry.code}
            alt={selectedCountry.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-fifth flex items-center justify-center">
            <span className="text-xl font-bold text-white">?</span>
          </div>
        )}
      </div>
      <div className="text-white mt-2">
        {selectedCountry ? selectedCountry.name : "Selecciona tu nacionalidad"}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="bg-fifth p-4 rounded-xl"
        style={{
          overlay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
          content: {
            position: "relative",
            top: "auto",
            right: "auto",
            bottom: "auto",
            left: "auto",
            maxWidth: "80%",
            width: "400px",
            maxHeight: "90vh",
            overflowY: "auto",
          },
        }}
      >
        <h2 className="text-center text-white font-bold mb-4">
          Selecciona tu país
        </h2>
        <div className="grid grid-cols-5 gap-4">
          {countries.map((country) => (
            <div
              key={country.code}
              className="cursor-pointer border border-yellow-500 rounded p-2 flex items-center justify-center"
              onClick={() => selectCountry(country)}
            >
              <WorldFlags
                code={country.code}
                alt={country.name}
                className="w-10 h-10"
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <button
            onClick={closeModal}
            className="bg-red-500 text-white p-2 rounded text-xs"
          >
            Cerrar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CountryFlagSelector;
