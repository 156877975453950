import React from "react";
import Container from "../../components/Container";
import { Link } from "react-router-dom";
import { riot_logo } from "../../components/AssetsBarrel";

const RiotLinkSuccess = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <Container className="flex flex-col items-center justify-center flex-grow">
        <div className="flex justify-center">
          <img
            src={riot_logo}
            alt="Riot Logo"
            style={{ width: "150px" }}
          />
        </div>
        <div className="text-4xl font-bold text-white text-center mt-8 mb-8">
          ¡Has vinculado correctamente tu cuenta de Riot!
        </div>
        <div className="flex justify-center">
          <Link
            to="/profile"
            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-3 px-6 rounded-lg text-xl"
            style={{ width: "fit-content" }}
          >
            Ir al perfil
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default RiotLinkSuccess;
