import React, { useState } from "react";
import { SiRiotgames } from "react-icons/si";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
  unranked,
} from "../../components/AssetsBarrel";

const Verify = ({ check, user, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("LAN");
  const [
    isUnlinkConfirmationModalVisible,
    setIsUnlinkConfirmationModalVisible,
  ] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const isDefaultSoloqAccount =
  data?.user_profile?.soloq_account == null || 
  data?.user_profile?.soloq_account === "default_soloq_account_id";


  const handleLinkRiotAccount = () => {
    let server = "la1";
    switch (selectedRegion) {
      case "BR":
        server = "br1";
        break;
      case "NA":
        server = "na1";
        break;
      case "LAN":
        server = "la1";
        break;
      case "LAS":
        server = "la2";
        break;
      default:
        server = "euw1";
        break;
    }
    const authUrl = `https://www.autoboost.gg/auth/riot/?server=${server}`;

    window.location.href = authUrl;
  };

  const openUnlinkConfirmationModal = () => {
    setIsUnlinkConfirmationModalVisible(true);
  };

  const handleUnlinkAccount = async () => {
    try {
      const response = await fetch("/unlink-soloq/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const responseData = await response.json();

      if (response.status === 200) {
        setResultMessage("La cuenta de SoloQ ha sido desvinculada.");
      } else {
        setResultMessage("Error al desvincular la cuenta de SoloQ.");
      }

      setResultModalVisible(true);
      setIsModalVisible(false);
      setIsUnlinkConfirmationModalVisible(false);
    } catch (error) {
      console.error("Error al procesar la solicitud:", error);
    }
  };

  const closeTotalModal = () => {
    setIsModalVisible(false);
    setIsUnlinkConfirmationModalVisible(false);
    setResultModalVisible(false);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const closeUnlinkConfirmModal = () => {
    setIsUnlinkConfirmationModalVisible(false);
  };

  const ResultModal = () => {
    return (
      <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
        <div className="modal-content bg-fifth p-8 rounded-xl">
          <div className="mb-4 text-white font-bold text-lg text-center">
            Aviso
          </div>
          <p className="text-white text-center mb-4">{resultMessage}</p>
          <div className="text-center mt-4">
            <button
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
              onClick={reloadPage}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const tierImages = {
    CHALLENGER: challenger,
    GRANDMASTER: grandmaster,
    MASTER: master,
    DIAMOND: diamond,
    EMERALD: emerald,
    PLATINUM: platinum,
    GOLD: gold,
    SILVER: silver,
    BRONZE: bronce,
    IRON: iron,
    UNRANKED: unranked,
  };

  return (
    <div>
      <button className="riot-button rounded-md" onClick={openModal}>
        <div className="flex items-center font-bold">
          <SiRiotgames className="mr-2" />
          {user.verified
            ? "Administrar tu cuenta de Riot"
            : "Linkear Cuenta de Riot"}
        </div>
      </button>

      {isModalVisible && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
          {isDefaultSoloqAccount ? (
            // Mostrar modal para vincular cuenta de Riot
            <div className="modal-content bg-fifth p-8 rounded-xl">
              <div className="mb-4 text-white font-bold text-lg text-center">
                Vincular una cuenta de Riot
              </div>
              <div className="text-center">
                <p className="text-white font-bold">
                  Vincula una nueva cuenta de Riot para continuar.
                </p>
              </div>
              <div className="mt-4 flex justify-center items-center">
                <label className="text-white mr-2">
                  Seleccione la región:
                </label>
                <select
                  className="border-2 border-yellow-500 rounded-md"
                  value={selectedRegion}
                  onChange={handleRegionChange}
                >
                  <option value="BR">BR</option>
                  <option value="NA">NA</option>
                  <option value="LAN">LAN</option>
                  <option value="LAS">LAS</option>
                  <option value="EUW">EUW</option>
                </select>
              </div>
              <div className="text-center mt-4">
                <button
                  className="modal-button bg-red-500 rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mr-2"
                  onClick={handleLinkRiotAccount}
                >
                  Vincular
                </button>
                <button
                  className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                  onClick={closeModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          ) : (
            // Mostrar modal normal de cuenta enlazada
            <div className="modal-content bg-fifth p-8 rounded-xl">
              <div className="mb-4 text-white font-bold text-lg text-center">
                Administrar tu cuenta de Riot
              </div>
              <div className="text-center">
                <p className="text-white font-bold">
                  La siguiente cuenta está vinculada a tu perfil:
                </p>
                <p className="text-yellow-500 font-bold">
                  {data?.soloq_data?.summonerName}
                </p>
              </div>
              <div className="grid grid-cols-2 gap-8 justify-center items-center">
                <div className="flex flex-col items-center text-white">
                  <img
                    src={`https://ddragon.leagueoflegends.com/cdn/14.10.1/img/profileicon/${data?.soloq_data?.profileIconId}.png`}
                    alt="Profile Icon"
                    className="border-4 border-yellow-500 rounded-full w-32 h-32 mt-4"
                  />
                  <p
                    className="border-2 border-yellow-500 rounded-full bg-yellow-500 text-white font-bold px-4"
                    style={{ marginTop: "-1rem" }}
                  >
                    {data?.soloq_data?.summonerLevel}
                  </p>
                </div>
                <div className="flex flex-col text-white justify-center items-center">
                  <div className="flex items-center justify-center">
                    <img
                      src={tierImages[data?.soloq_data?.tier.toUpperCase()]}
                      alt="Tier Icon"
                      className="w-32 h-32 mr-4"
                    />
                    <div className="text-center">
                      <div>
                        {data?.soloq_data?.tier.toUpperCase()}{" "}
                        {data?.soloq_data?.rank}
                      </div>
                      <div>{data?.soloq_data?.leaguePoints} LP</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-4">
                <button
                  className="modal-button bg-red-500 rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60  mr-2"
                  onClick={openUnlinkConfirmationModal}
                >
                  Desvincular
                </button>
                <button
                  className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                  onClick={closeModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {isUnlinkConfirmationModalVisible && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
          <div className="modal-content bg-fifth p-8 rounded-xl">
            <div className="mb-4 text-white font-bold text-lg text-center">
              Confirmar Desvinculación
            </div>
            <p className="text-white text-center mb-4">
              ¿Estás seguro que deseas desvincular la cuenta:{" "}
              <span className="font-bold text-yellow-500">
                {data?.soloq_data?.summonerName}
              </span>
              ?
            </p>
            <div className="flex justify-center">
              <button
                className="modal-button bg-red-500 rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mr-2"
                onClick={handleUnlinkAccount}
              >
                Si
              </button>
              <button
                className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                onClick={closeUnlinkConfirmModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {resultModalVisible && <ResultModal />}
    </div>
  );
};

export default Verify;
