import React from "react";
import ActionButton from "../../components/ActionButton";
import { TbCoins } from "react-icons/tb";
import {
  top,
  jungler,
  mid,
  adc,
  support,
  coach,
  analyst,
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
  LAN,
  LAS,
} from "../../components/AssetsBarrel";

const AgentCard = ({
  name,
  id,
  agentId,
  avatar,
  role,
  region,
  tier,
  rank,
  lp,
  summoner,
  coins,
  invitationStatus,
  onClick,
  userData,
  invitations,
}) => {
  const tierImages = {
    CHALLENGER: challenger,
    GRANDMASTER: grandmaster,
    MASTER: master,
    DIAMOND: diamond,
    EMERALD: emerald,
    PLATINUM: platinum,
    GOLD: gold,
    SILVER: silver,
    BRONZE: bronce,
    IRON: iron,
  };

  const roleImages = {
    Top: top,
    Jungler: jungler,
    Mid: mid,
    ADC: adc,
    Support: support,
    Coach: coach,
    Analyst: analyst,
  };
  const regionImages = {
    LAN: LAN,
    LAS: LAS,
  };
  if (region !== "LAN" && region !== "LAS") {
    region = "LAN";
  }
  const opggUrl = `https://www.op.gg/summoners/${region}/${summoner}`;

  const isCaptain = () => {
    if (userData && userData.in_team !== undefined) {
      return (
        userData.in_team &&
        (userData.team_info.is_captain || userData.team_info.is_subcaptain)
      );
    }
    return false;
  };

  const isInvited = invitations.some(
    (invitation) => invitation.player_profile === agentId
  );

  let buttonText = "Invitar";

  if (
    invitationStatus === "already_invited" ||
    invitationStatus === "invited" ||
    isInvited
  ) {
    buttonText = "Ya invitado";
  }

  return (
    <div className="myGradient flex justify-between items-center py-4 px-8 rounded-md text-white text-xs sm:text-sm md:text-base transform transition-transform hover:border hover:border-yellow-500 hover:scale-105 custom-wrap-pretty">
      <div className="flex flex-col md:flex-row items-center flex-1 text-center gap-2 md:gap-5 justify-start relative max-w-none md:left-12 ">
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            src={`https://cdn.discordapp.com/avatars/${String(
              id
            )}/${avatar}.png`}
            alt="Discord Avatar"
            className="rounded-full w-10 sm:w-12 bg-gray-400 max-w-none bg-opacity-30"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
            }}
          />
          <img
            src={roleImages[role]}
            alt={`${role} Icon`}
            style={{
              position: "absolute",
              bottom: -3,
              right: -4,
              borderRadius: "50%",
              width: "1.5rem",
              height: "1.5rem",
            }}
            className="bg-fifth"
          />
        </div>

        <div className="text-center overflow-hidden whitespace-nowrap max-w-[5em] md:max-w-[10em]">
          {name.length > 15
            ? `${name.slice(0, 15).charAt(0).toUpperCase()}${name
                .slice(0, 15)
                .slice(1)}`
            : `${name.charAt(0).toUpperCase()}${name.slice(1)}`}
        </div>
      </div>
      <div className="flex-1 items-center text-center gap-1">
        <img
          src={regionImages[region]}
          alt={region}
          className="w-6 sm:w-8"
          style={{ display: "block", margin: "0 auto" }}
        />
        {region}
      </div>
      <div className="flex flex-col flex-1 items-center gap-0 sm:flex-row  sm:gap-2 sm:items-center">
        <div>
          <a href={opggUrl} target="_blank" rel="noopener noreferrer">
            <img src={tierImages[tier]} alt={tier} className="w-10 sm:w-16" />
          </a>
        </div>
        <div className="flex flex-col min-w-1/6 flex-1 text-center">
          <div>
            {" "}
            <a href={opggUrl} target="_blank" rel="noopener noreferrer">
              {summoner}
            </a>
          </div>
          <div>
            <a href={opggUrl} target="_blank" rel="noopener noreferrer">
              {tier.charAt(0).toUpperCase() + tier.slice(1).toLowerCase()}{" "}
              {rank} {lp} LP
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-1 items-center justify-center">
        <TbCoins />
        {coins}
      </div>
      <div className="flex-1 items-center text-center">
        <ActionButton
          onClick={() => onClick(id, name)}
          disabled={
            !isCaptain() ||
            invitationStatus === "already_invited" ||
            invitationStatus === "invited" ||
            isInvited
          }
        >
          {buttonText}
        </ActionButton>
      </div>
    </div>
  );
};

export default AgentCard;
