import React from "react";
import { BsArrowRight, BsArrowDown } from "react-icons/bs";

const CardsHeading = ({ titles }) => {
  return (
    <div
      className="flex justify-between w-full items-center py-4 px-8 rounded-md text-white text-xs sm:text-sm md:text-base bg-fifth"
    >
      {titles.map((title, index) => (
        <div
          key={index}
          className={`${
            title === "INVOCADOR" ? "hidden sm:block" : ""
          } flex-1 items-center text-center`}
        >
          <div className="flex flex-col items-center">
            <div className={title === "Desde" ? "text" : "text-center "}>
              {title}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};


export default CardsHeading;
