import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Images from "../../components/ImagesContainer";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
} from "../../components/AssetsBarrel";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { PlayerCard } from "../../components/PlayerCard";
import CardLoader from "../../components/CardLoader";

const MyInvScrimCard = ({
  scrim,
  handleCancelClick,
  handleAcceptClick,
  isCaptain,
  sent,
  isWorking,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const goToTeamPage = (teamName) => {
    navigate(`/league/equipos/${teamName}`);
  };

  const fetchTeamData = async () => {
    setIsLoading(true);
    let filteredMembers = [];

    try {
      const teamId = sent ? scrim.receiving_team : scrim.requesting_team;
      const response = await fetch(`/api/team/${teamId}`);
      const data = await response.json();
      setTeamData(data);

      if (!sent) {

        const scrimPlayersResponse = await Axios.get(
          `/api/scrim-invitation-players/${scrim.id}/`
        );
        const scrimPlayers = scrimPlayersResponse.data.map(
          (player) => player.id
        );
        filteredMembers = data.members.filter((member) =>
          scrimPlayers.includes(member.id)
        );
      } else {
        const scrimPlayersResponse = await Axios.get(
          `/api/scrim-request-players/${scrim.scrim_request}/`
        );
        const scrimPlayers = scrimPlayersResponse.data.map(
          (player) => player.id
        );
        filteredMembers = data.members.filter((member) =>
          scrimPlayers.includes(member.id)
        );
      }

      const updatedTeamData = { ...data, members: filteredMembers };
      console.log(updatedTeamData);
      setTeamData(updatedTeamData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching team data:", error);
      setIsLoading(false);
    }
  };

  const handleChevronClick = () => {
    if (!expanded && !teamData) {
      fetchTeamData();
    }
    setExpanded(!expanded);
  };
  const getPlayerType = (discordTag, captain, subcaptains, staff) => {
    if (discordTag === captain) {
      return "captain";
    } else if (subcaptains.includes(discordTag)) {
      return "subcaptain";
    } else if (staff.includes(discordTag)) {
      return "staff";
    } else {
      return "player";
    }
  };
  const renderPlayerCards = () => {
    if (!teamData || !teamData.members) {
      return null;
    }
    const members = teamData.members.filter((member) =>
      teamData.players.includes(member.discord_tag)
    );
    const captain = teamData.captain && teamData.captain[0];
    const subcaptains = teamData.subcaptains;

    return members.map((member, index) => {
      const memberType = getPlayerType(
        member.discord_tag,
        captain,
        subcaptains,
        teamData.staff
      );

      return (
        <PlayerCard
          key={index}
          member={memberType}
          name={member.discord_tag}
          role={member.role}
          region={member.region}
          soloq_account={member.soloq_account__summonerName}
          elo={`${member.soloq_account__tier} ${member.soloq_account__rank}`}
          tier={member.soloq_account__tier}
          rank={member.soloq_account__rank}
          lp={member.soloq_account__leaguePoints}
          active={member.is_active}
          summoner={member.soloq_account__summonerName}
          user_id={member.user_id}
          avatar_id={member.avatar_id}
          coins={member.coins}
        />
      );
    });
  };

  const getRankImage = (rating) => {
    if (rating >= 10.0) return challenger;
    if (rating >= 9.0) return grandmaster;
    if (rating >= 8.0) return master;
    if (rating >= 7.0) return diamond;
    if (rating >= 6.0) return emerald;
    if (rating >= 5.0) return platinum;
    if (rating >= 4.0) return gold;
    if (rating >= 3.0) return silver;
    if (rating >= 2.0) return bronce;
    return iron;
  };

  

  return (
    <div
      className="myGradient flex flex-col justify-between items-center py-2 pl-4 pr-4 rounded-md text-white
  text-xs sm:text-sm md:text-base transform transition-transform hover:border hover:border-yellow-500 relative"
    >
      <div className="flex justify-around w-full items-center pl-4 pr-2">
        <div className="flex flex-col gap-1 items-center w-16 sm:w-20">
          <img
            src={Images[scrim.team_image]}
            alt="Rank"
            className="inline w-12 sm:w-16 md:w-20"
          />
          <div
            onClick={() =>
              sent
                ? goToTeamPage(scrim.receiving_team)
                : goToTeamPage(scrim.requesting_team)
            }
            className="hover:underline cursor-pointer hover:text-rose-500 text-xs sm:text-sm md:text-base"
          >
            {sent ? scrim.receiving_team : scrim.requesting_team}
          </div>
        </div>
        <div className="flex flex-col items-center w-16 sm:w-32">
          <img
            src={getRankImage(scrim.average_rating)}
            alt="Rank"
            className="inline w-12 sm:w-16 md:w-20"
          />
        </div>
        <div className="w-16 sm:w-28 overflow-hidden flex justify-center">
          {scrim.comments}
        </div>
        {isCaptain() && (
          <div className="flex flex-col gap-1 items-center">
            {handleAcceptClick && (
              <div>
                <button
                  className={`bg-blue-500 text-xs hover:bg-blue-700 text-white font-bold py-2 px-2 sm:px-4 rounded w-16 sm:w-24 ${
                    isWorking
                      ? "opacity-50 bg-blue-300 cursor-not-allowed"
                      : "hover:bg-blue-700"
                  }`}
                  onClick={handleAcceptClick}
                  disabled={isWorking}
                >
                  Aceptar
                </button>
              </div>
            )}

            {handleCancelClick && (
              <div>
                <button
                  className={`bg-red-500 text-xs hover:bg-red-700 text-white font-bold py-2 px-2 sm:px-4 rounded w-16 sm:w-24 ${
                    isWorking ? "opacity-50 bg-red-300 cursor-not-allowed" : ""
                  }`}
                  onClick={() => handleCancelClick()}
                  disabled={isWorking}
                >
                  Cancelar
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div onClick={handleChevronClick} className="vertical-bar"></div>
      <div className="flex justify-end w-full absolute bottom-2 right-2">
        {expanded ? (
          <FaChevronUp
            className="cursor-pointer hover:text-rose-500"
            onClick={handleChevronClick}
          />
        ) : (
          <FaChevronDown
            className="cursor-pointer hover:text-rose-500"
            onClick={handleChevronClick}
          />
        )}
      </div>

      {expanded && (
        <div className="mt-4 w-full flex flex-col gap-2 px-4">
          {isLoading ? (
            <CardLoader />
          ) : (
            <div className="flex flex-col gap-2">{renderPlayerCards()}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyInvScrimCard;
