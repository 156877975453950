import React from "react";
import {
  LAN,
  LAS,
} from "../../components/AssetsBarrel";
import Images from "../../components/ImagesContainer";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const TeamCard = ({
  name,
  image,
  open,
  region,
  captain,
  captain_id,
  captain_avatar,
}) => {
  const navigate = useNavigate();

  const regionImages = {
    LAN: LAN,
    LAS: LAS,
  };

  const goToTeamPage = (teamName) => {

    navigate(`/league/equipos/${teamName}`);
  };

  return (
    <div
      className="flex flex-col gap-2 bg-fifth p-4 rounded-xl h-64
    transform transition-transform hover:scale-105 hover:border hover:border-yellow-500"
    >
      <div className="flex justify-between text-xs text-white items-center">
        <div className="flex flex-col gap-0.5">
          <div>
            <img
              src={regionImages[region]}
              alt={region}
              width={24}
              height={24}
            />
          </div>
          <div> {region}</div>
        </div>
        <div>
          <div className="flex flex-col gap-0.5 items-center">
            <img
              src={`https://cdn.discordapp.com/avatars/${String(
                captain_id
              )}/${captain_avatar}.png`}
              alt="Discord Avatar"
              width={24}
              height={24}
              className="rounded-full"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
              }}
            />
            {captain}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div>
          <img
            src={Images[image]}
            alt={image}
            className="min-h-24	min-w-24 w-26 h-24 object-contain	"
            onClick={() => goToTeamPage(name)}
          />
        </div>
        <div
          className="text-zinc-400 text-sm sm:text-base cursor-pointer
          hover:text-yellow-500 hover:underline"
          onClick={() => goToTeamPage(name)}
        >
          {name}
        </div>
      </div>
      <div className="flex justify-center gap-1">
        {open ? (
          <AiFillCheckCircle color="green" />
        ) : (
          <AiFillCloseCircle color="red" />
        )}
        <span className="text-white text-sm">Reclutando</span>
      </div>
    </div>
  );
};

export default TeamCard;
