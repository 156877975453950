import React from "react";
import {
  TbProgress,
  TbProgressCheck,
  TbTrophy,
  TbTrophyOff,
  TbCoins,
} from "react-icons/tb";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiTeamFill } from "react-icons/ri";
import Loader from "../../components/Loader";

const BetsList = ({ bets, loading }) => {
  return (
    <div className="flex flex-col gap-2">
      {loading ? (
        <div className="flex justify-center items-center h-screen bg-black bg-opacity-50 fixed top-0 left-0 w-full z-50">
          <Loader />
        </div>
      ) : bets.length === 0 ? (
        <div className="myGradient text-white p-4 text-sm">
          No has realizado ninguna predicción aún, realiza alguna en el servidor de
          discord
        </div>
      ) : (
        bets.map((bet, index) => (
          <div
            key={index}
            className="myGradient text-white p-4 flex gap-2 justify-between text-sm transform transition-transform hover:border hover:border-yellow-500 hover:scale-105 rounded"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ flex: 1 }} className="hidden sm:flex">
              {bet.status === "En Curso" ? (
                <div className="flex gap-1 items-center">
                  <TbProgress size={20} color="yellow"/>
                  En Curso
                </div>
              ) : (
                <div className="flex gap-1 items-center">
                  <TbProgressCheck size={20} color="green"/>
                  Finalizado
                </div>
              )}
            </div>
            <div style={{ flex: 1 }}>
              {bet.result === "GANADA" ? (
                <div className="flex gap-1 items-center">
                  <TbTrophy size={20} color="green"/>
                  Victoria
                </div>
              ) : bet.result === "PERDIDA" ? (
                <div className="flex gap-1 items-center">
                  <TbTrophyOff size={20} color="red"/>
                  Derrota
                </div>
              ) : (
                <div className="flex gap-1 items-center">
                  <HiDotsHorizontal size={20} color="blue"/>
                  Esperando
                </div>
              )}
            </div>
            <div style={{ flex: 1 }} className="flex gap-1 items-center">
              <RiTeamFill />
              {bet.team_bet}
            </div>
            <div style={{ flex: 1 }} className="flex gap-1 items-center">
              <TbCoins color="yellow"/>
              {bet.coins_bet}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default BetsList;
