import React, { useState, useEffect } from "react";
import Axios from "axios";
import ScrimModal from "./ScrimModal";
import MessageModal from "./MessageModal";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import ScrimCard from "./ScrimCard";
import CardsHeading from "../../components/CardsHeading";
const ScrimList = ({ scrims, userData }) => {
  const [selectedScrim, setSelectedScrim] = useState(null);
  const [teamDetails, setTeamDetails] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [comments, setComments] = useState("");
  const [searchRegion, setSearchRegion] = useState("ALL");
  const [searchElo, setSearchElo] = useState("ALL");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);


  const isCaptain = () => {
    if (!userData) {
      return false; 
    }
    
    return (
      userData.in_team &&
      (userData.team_info.is_captain || userData.team_info.is_subcaptain)
    );
  };

  const handleAcceptClick = async (scrim) => {
    try {
      const response = await Axios.get(`/api/team/${scrim.team}/`);
      setTeamDetails(response.data);
      setSelectedScrim(scrim);
    } catch (error) {
      console.error("Error fetching team details:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await Axios.get(
          `/api/team/${userData.team_info.team_name}`
        );
        setTeamMembers(response.data.members);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    if (selectedScrim) {
      fetchTeamMembers();
    }
  }, [selectedScrim]);

  const handlePlayerSelection = (playerId) => {
    const updatedSelection = [...selectedPlayers];

    if (updatedSelection.includes(playerId)) {
      const index = updatedSelection.indexOf(playerId);
      updatedSelection.splice(index, 1);
    } else {
      if (updatedSelection.length < 7) {
        updatedSelection.push(playerId);
      }
    }

    setSelectedPlayers(updatedSelection);
  };

  const handleSendInvitation = async () => {
    setIsLoading(true);
    try {
      const response = await Axios.post("/api/create-scrim-invitation/", {
        scrim_request_id: selectedScrim.id,
        players: selectedPlayers,
        comments: comments,
      });

      setMessageModalContent("Solicitud de scrim enviada exitosamente");
      setSelectedScrim(null);
      setMessageModalOpen(true);
    } catch (error) {
      console.error("Error sending scrim invitation:", error);
      let errorMessage = "There was an error sending the invitation.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      } else {
        errorMessage += " " + error.message;
      }

      setMessageModalContent(errorMessage);
      setSelectedScrim(null);
      setMessageModalOpen(true);
    }
    setIsLoading(false);
  };

  const closeModalAndNavigate = (path) => {
    setMessageModalOpen(false);
    navigate(path);
  };

  const filteredScrims = scrims.filter((scrim) => {
    if (searchRegion !== "ALL" && scrim.region !== searchRegion) return false;

    if (searchElo !== "ALL" && scrim.average_rating !== searchElo) return false;

    if (startDate && new Date(scrim.date) < new Date(startDate)) return false;

    if (endDate && new Date(scrim.date) > new Date(endDate)) return false;

    return true;
  });
  const getTitles = () => {
    if (width < 640) {
      if (isCaptain()) {
        return ["Equipo", "Elo", "Fecha", "Accion"];
      }
      return ["Equipo", "Elo", "Fecha"];
    }
    return isCaptain()
      ? ["Equipo", "Elo", "Fecha", "Hora", "Accion"]
      : ["Equipo", "Elo", "Fecha", "Hora"];
  };

  return (
    <div className="scrimList">
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-60">
          <Loader />
        </div>
      )}
      <div className="mb-2">
        <div className="filter-section flex gap-1 text-white flex-wrap">
          <select
            value={searchRegion}
            onChange={(e) => setSearchRegion(e.target.value)}
            className="bg-zinc-300 border p-1 text-black cursor-pointer"
          >
            <option value="ALL">Todas las Regiones</option>
            <option value="LAN">LAN</option>
            <option value="LAS">LAS</option>
          </select>

          <select
            value={searchElo}
            onChange={(e) => setSearchElo(e.target.value)}
            className="bg-zinc-300 border p-1 cursor-pointer text-black"
          >
            <option value="ALL">All Elos</option>
            <option value="10.00">Challenger</option>
            <option value="9.00">Grandmaster</option>
            <option value="8.00">Master</option>
            <option value="7.00">Diamond</option>
            <option value="6.00">Emerald</option>
            <option value="5.00">Platinum</option>
            <option value="4.00">Gold</option>
            <option value="3.00">Silver</option>
            <option value="2.00">Bronce</option>
            <option value="1.00">Iron</option>
          </select>

          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="bg-zinc-300 border p-1 text-black cursor-pointer"
            placeholder="Desde"
          />

          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="bg-zinc-300 border p-1 text-black cursor-pointer"
            placeholder="Hacia"
          />

          <button
            onClick={() => {
              setSearchRegion("ALL");
              setSearchElo("ALL");
              setStartDate("");
              setEndDate("");
            }}
            className="bg-primary py-2 px-4 hover:bg-secondary"
          >
            Reset Filters
          </button>
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-2">
          <CardsHeading titles={getTitles()} />
          {filteredScrims.map((scrim, index) => (
            <div key={index}>
              <ScrimCard
                userData={userData}
                scrim={scrim}
                handleAcceptClick={() => handleAcceptClick(scrim)}
                isCaptain={isCaptain}
              />
            </div>
          ))}
        </div>
      </div>

      <ScrimModal
        title="Scrim"
        isOpen={!!selectedScrim}
        onClose={() => setSelectedScrim(null)}
        onClick={handleSendInvitation}
        className="bg-fifth p-4 rounded-xl"
      >
        <div className="w-full flex flex-col gap-2 text-white bg-fifth p-4 rounded-xl">
          <div className="flex justify-between border border-blue-500 p-2 rounded text-sm flex-wrap">
            <div>Equipo: {teamDetails?.name}</div>
            <div>Region: {teamDetails?.region}</div>
            <div>Capitan: {teamDetails?.captain[0]}</div>
            <div>Fecha: {selectedScrim?.date}</div>
            <div>Hora: {selectedScrim?.time}</div>
          </div>
          <div className="w-full flex flex-col gap-2 mt-4">
            <div className="text-lg font-bold mb-2">
              Jugadores del equipo enemigo:
            </div>
            <div className="grid grid-cols-2 gap-4">
              {teamDetails?.members.map((member) => (
                <div
                  key={member.id}
                  className="p-4 border border-gray-300 rounded flex items-center"
                >
                  <div>
                    <p className="font-bold mb-2 text-sm">
                      Discord: {member.discord_tag}
                    </p>
                    <p className="text-xs">
                      Summoner: {member.soloq_account__summonerName}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex flex-col gap-2 mt-4">
            <div className="text-lg font-bold mb-2">
              Selecciona tus jugadores:
            </div>
            <div className="grid grid-cols-2 gap-4">
              {teamMembers.map((member) => (
                <div
                  key={member.id}
                  className="p-4 border border-gray-300 rounded flex items-center"
                >
                  <input
                    type="checkbox"
                    checked={selectedPlayers.includes(member.id)}
                    onChange={() => handlePlayerSelection(member.id)}
                  />
                  <div className="ml-2">
                    <p className="font-bold mb-2 text-sm">
                      Discord: {member.discord_tag}
                    </p>
                    <p className="text-xs">
                      Summoner: {member.soloq_account__summonerName}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex flex-col gap-2 mt-4">
            <div className="text-lg font-bold mb-2">Comentarios:</div>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className="w-full p-2 border rounded bg-black bg-opacity-50"
              placeholder="Escribe tu comentario aquí..."
            ></textarea>
          </div>
        </div>
      </ScrimModal>

      <MessageModal
        isOpen={messageModalOpen}
        onClose={() => {
          messageModalContent === "Solicitud de scrim enviada exitosamente"
            ? closeModalAndNavigate("/league/scrims/invitations-sent")
            : closeModalAndNavigate("/league/scrims");
        }}
      >
        {messageModalContent}
      </MessageModal>
    </div>
  );
};

export default ScrimList;
