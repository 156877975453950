import React from "react";
import Container from "../../components/Container";
import { FaUsers } from "react-icons/fa";
import { BiTransfer } from "react-icons/bi";
import { RxUpdate } from "react-icons/rx";
import TitleContainer from "../../components/TitleContainer";
import Transfers from "../../components/Transfers";
import WidgetBot from "@widgetbot/react-embed";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader";

const Index = () => {
  const [transfers, setTransfers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTransfers = async () => {
      try {
        const response = await axios.get("/api/player-status-logs/?page_size=2");
        setTransfers(response.data['results']);
        setIsLoading(false);
      } catch (error) {
        console.error("There was an error fetching the data", error);
        setIsLoading(false); 
      }
    };

    fetchTransfers();
  }, []);

  return (
    <Container>
      <div className="flex flex-col gap-2 mt-4">
        <TitleContainer
          text={"Bienvenidos a la pagina"}
          icon={<FaUsers />}
          color="--primary-color"
          flex="end"
        />
        <TitleContainer
          text={"Últimos Movimientos"}
          icon={<BiTransfer />}
          color="--fifth-color"
          flex="start"
          link="/league/transferencias"
        />
        {isLoading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : (
          <Transfers transfers={transfers} day />
        )}
        <TitleContainer
          text={"Actualizaciones"}
          icon={<RxUpdate />}
          color="--fifth-color"
          flex="start"
        />
        <WidgetBot
          server="294295653620645888"
          channel="385149213153034240"
          height="50vh"
        />
      </div>
    </Container>
  );
};

export default Index;
