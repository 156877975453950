import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../components/Loader"; 

const BanChampionModal = ({ tournamentId, closeModal }) => {
  const [champions, setChampions] = useState([]);
  const [filteredChampions, setFilteredChampions] = useState([]);
  const [selectedChampion, setSelectedChampion] = useState("");
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [bannedChampions, setBannedChampions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseModalContent, setResponseModalContent] = useState("");
  useEffect(() => {
    async function fetchVersion() {
      try {
        const versionResponse = await axios.get(
          "https://ddragon.leagueoflegends.com/api/versions.json"
        );
        const versions = versionResponse.data;
        const firstVersion = versions[0];
        setCurrentVersion(firstVersion);
      } catch (error) {
        console.error("Error fetching version:", error);
      }
    }
    fetchVersion();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const bannedChampionsResponse = await axios.get(
          `api/tournament/${tournamentId}/rules`
        );

        const bannedChampionsData = bannedChampionsResponse.data.filter(
          (item) => item.banned && typeof item.banned === "string"
        );

        const bannedChampionNames = bannedChampionsData.map(
          (bannedItem) => bannedItem.banned
        );

        setBannedChampions(bannedChampionNames);

        const versionResponse = await axios.get(
          "https://ddragon.leagueoflegends.com/api/versions.json"
        );
        const versions = versionResponse.data;
        const firstVersion = versions[0];

        const championsResponse = await axios.get(
          `https://ddragon.leagueoflegends.com/cdn/${firstVersion}/data/en_US/champion.json`
        );
        const championData = championsResponse.data.data;
        const championList = Object.keys(championData);

        const filteredChampions = championList.filter(
          (champion) => !bannedChampionNames.includes(champion)
        );

        setChampions(filteredChampions);
        setFilteredChampions(filteredChampions);

        setIsLoadingInitial(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [tournamentId]);

  const handleBanChampion = async (championName) => {
    try {
      setModalOpen(false);

      setIsLoadingAction(true);

      const response = await axios.post("tournament/exchange/rule-ticket/", {
        tournament_id: tournamentId,
        ban_type: "campeón",
        ban_name: championName,
      });

      setResponseModalContent(response.data.detail);

      setShowResponseModal(true);

    } catch (error) {
      setIsLoadingAction(false);
      setResponseModalContent(
        error.response?.data?.detail || "Error al banear campeón"
      );
      setShowResponseModal(true);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = champions
      .filter((champion) => champion.toLowerCase().includes(term))
      .filter((champion) => !bannedChampions.includes(champion));
    setFilteredChampions(filtered);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
    setShowResponseModal(false);
    closeModal();
    window.location.reload()
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="modal-content bg-fifth p-4 rounded-xl">
        <h2 className="text-white font-bold text-xl text-center mb-4">
          Selecciona un Campeón para Banear
        </h2>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Buscar campeón..."
          className="bg-gray-200 rounded-md px-3 py-2 mb-4 w-full"
        />
        <div className="overflow-y-scroll max-h-60 md:max-h-96 p-2 scrollbar-thin scrollbar-thumb-yellow-700 scrollbar-track-blue-300  scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
          <div
            className={`grid ${
              isLoadingInitial
                ? "grid-cols-1 py-4 px-4"
                : "grid-cols-3 md:grid-cols-5"
            } gap-2 md:gap-4`}
          >
            {isLoadingInitial ? (
              <div className="flex items-center justify-center w-full h-full my-2">
                <Loader />
              </div>
            ) : (
              filteredChampions.map((championKey, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedChampion(championKey)}
                  className={`cursor-pointer relative text-center ${
                    championKey === selectedChampion
                      ? "border-gradient-yellow border-2 boder-opacity-50 rounded-lg"
                      : "border-2 border-transparent rounded-lg"
                  }`}
                >
                  <img
                    src={`https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/champion/${championKey}.png`}
                    alt={championKey}
                    className="w-full h-auto md:h-24 object-contain"
                  />
                  <p className="text-white">{championKey}</p>
                </button>
              ))
            )}
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <div className="flex space-x-4">
            <button
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
              onClick={() => handleBanChampion(selectedChampion)}
              disabled={!selectedChampion}
            >
              Banear
            </button>
            <button
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
              onClick={closeModal}
            >
              Cancelar
            </button>
            {showResponseModal && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
                  <h3 className="font-bold text-lg">Nueva Regla de Torneo</h3>
                  <p>{responseModalContent}</p>
                  <button
                    onClick={closeModalHandler}
                    className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            )}
            {isLoadingAction && (
              <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BanChampionModal;
