import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { RiTeamFill } from "react-icons/ri";
import TeamList from "./TeamList";
import UserTeam from "./UserTeam";
const Index = ({ isUserLoggedIn }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [userProfile2, setUserProfile2] = useState(null)
  useEffect(() => {
    if (isUserLoggedIn) {
      axios.get("/api/check_user_profile/")
        .then(response => {
          setUserProfile(response.data.user_profile);
          setUserProfile2(response.data)
        })
        .catch(error => console.error("Error fetching user profile:", error));
    }
  }, [isUserLoggedIn]); 

  return (
    <Container>
      <div className="mt-4 flex flex-col gap-2">
        {isUserLoggedIn ? (
          <>
          <TitleContainer
            text="Mi Equipo"
            icon={<RiTeamFill />}
            color="--fifth-color"
            flex="end"
          />
          {userProfile && (<UserTeam userProfile={userProfile}/>)}
          </>
        ) : null}

        <TitleContainer
          text="Equipos: Crear un equipo tiene un costo de 500 coins, unirte a uno es gratis"
          icon={<RiTeamFill />}
          color="--primary-color"
          flex="start"
        />
        <TeamList userProfile2={userProfile2} isUserLoggedIn={isUserLoggedIn}/>
      </div>
    </Container>
  );
};

export default Index;
