import React from "react";

const Button = ({ children, primary, disabled, onClick, customStyle, isVerified }) => {
  let bgColorClass = isVerified ? "bg-lime-700" : "bg-primary"; 

  const baseColorClass = primary
    ? `modal-button ${bgColorClass} rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60`
    : `modal-button ${bgColorClass} rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60`;

  const buttonStyles = customStyle || "";

  const disabledStyles = disabled
    ? isVerified
      ? "cursor-not-allowed"
      : "opacity-20 cursor-not-allowed bg-gray-400 hover:bg-gray-400"
    : ""; 

  return (
    <button
      onClick={onClick}
      className={`${baseColorClass} rounded-md font-bold text-xs ${disabledStyles} py-2 px-4 text-sm sm:text-base ${buttonStyles}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
