import React, { useState, useEffect } from "react";
// lol roles
import top from "../../assets/Position_Challenger-Top.png";
import jungler from "../../assets/Position_Challenger-Jungle.png";
import mid from "../../assets/Position_Challenger-Mid.png";
import adc from "../../assets/Position_Challenger-Bot.png";
import support from "../../assets/Position_Challenger-Support.png";
import coach from "../../assets/icons8-coach-72.png";
import analyst from "../../assets/icons8-analyst-72.png";
// valorant roles
import duelist from "../../assets/duelist.png";
import initiator from "../../assets/initiator.png";
import sentinel from "../../assets/sentinel.png";
import controller from "../../assets/controller.png";
// fifa roles
import captain from "../../assets/fifacaptain.png";
import shortfreekick from "../../assets/fifashortfreekick.png";
import longfreekick from "../../assets/fifalongfreekick.png";
import penalty from "../../assets/fifapenalty.png";
import leftcorner from "../../assets/fifarightcorner.png";
import rightcorner from "../../assets/fifarightcorner.png";
import { BsQuestionCircle } from "react-icons/bs";

const Role = ({ selectedRole, setRole, game }) => {

  const lolRoles = [
    { id: "top", name: "Top", icon: top },
    { id: "jungler", name: "Jungler", icon: jungler },
    { id: "mid", name: "Mid", icon: mid },
    { id: "adc", name: "ADC", icon: adc },
    { id: "support", name: "Support", icon: support },
    { id: "coach", name: "Coach", icon: coach },
    { id: "analyst", name: "Analyst", icon: analyst },
  ];

  const valorantRoles = [
    { id: "duelist", name: "Duelist", icon: duelist },
    { id: "initiator", name: "Initiator", icon: initiator },
    { id: "sentinel", name: "Sentinel", icon: sentinel },
    { id: "controller", name: "Controller", icon: controller },
  ];

  const fifaRoles = [
    { id: "captain", name: "Captain", icon: captain },
    { id: "shortfreekick", name: "Short Free Kick", icon: shortfreekick },
    { id: "longfreekick", name: "Long Free Kick", icon: longfreekick },
    { id: "penalty", name: "Penalty", icon: penalty },
    { id: "leftcorner", name: "Left Corner", icon: leftcorner },
    { id: "rightcorner", name: "Right Corner", icon: rightcorner },
  ];

  let roles = lolRoles;

  if (game === "Valorant") {
    roles = valorantRoles;
  } else if (game === "FIFA") {
    roles = fifaRoles;
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [defaultIcon, setDefaultIcon] = useState("");
  const [defaultRoleName, setDefaultRoleName] = useState("");

  useEffect(() => {
    if (selectedRole) {
      const role = roles.find((role) => role.name === selectedRole);
      if (role) {
        setDefaultIcon(role.icon);
        setDefaultRoleName(role.name);
      }
    } else {
      setDefaultIcon(<BsQuestionCircle size={40} />);
      setDefaultRoleName("Selecciona tu rol");
    }
  }, [selectedRole, roles]);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleRoleSelection = (role) => {
    if (selectedRole === role.name) {
      setRole(null);
      setDefaultIcon(<BsQuestionCircle size={40} />);
      setDefaultRoleName("Selecciona tu rol");
    } else {
      setRole(role.name);
      setDefaultIcon(role.icon);
      setDefaultRoleName(role.name);
    }
  };

  return (
    <div className="relative">
      <div
        className="p-4 absolute inset-0 flex flex-col items-center cursor-pointer"
        onClick={openModal}
      >
        <div className="bg-black bg-opacity-50 p-4 rounded  w-36 md:w-50 lg:w-60 h-36 md:h-50 lg:h-60 marker:cursor-pointer flex flex-col items-center justify-center text-center">
          {selectedRole ? (
            <img
              src={defaultIcon}
              alt={defaultRoleName}
              className="mb-2"
              width={60}
              height={60}
            />
          ) : (
            defaultIcon
          )}
          <span className="mb-2 text-white lg:text-2xl flex items-center">
            {defaultRoleName}
          </span>
        </div>
      </div>

      {isModalVisible && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
          <div
            className="modal-content bg-fifth p-4 rounded-xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mb-4 text-white font-bold lg:text-xl text-sm	 text-center">
              Seleccionar rol
            </div>
            <div className="grid grid-cols-4 gap-4">
              {roles.map((role) => (
                <div
                  key={role.id}
                  className={`cursor-pointer ${
                    selectedRole === role.name ? "opacity-100" : "opacity-50"
                  }`}
                  onClick={() => {
                    handleRoleSelection(role);
                  }}
                >
                  <div className="text-center text-white cursor-pointer relative">
                    <img
                      src={role.icon}
                      alt={role.name}
                      width={60}
                      height={60}
                      className="mx-auto"
                    />
                    <div className="mt-2 text-lg">{role.name}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-4">
              <button
                className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                onClick={closeModal}
              >
                Seleccionar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Role;
