import React from "react";
import Images from "../../components/ImagesContainer";
import Heading from "../../components/Heading";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Paragraph } from "../../components/Paragraph";

const TeamPageCard = ({ teamData }) => {
  return (
    <div className=" w-full bg-opacity-40 bg-black py-4 px-8 rounded-lg">
      <div className=" flex flex-col h-full justify-center items-center lg:items-start">
        <div className="flex gap-2">
          <div className="flex flex-col lg:flex-row gap-4 sm:gap-8 text-center justify-center self-center items-center">
            <img
              src={Images[teamData.image]}
              alt={teamData.image}
              className="object-contain w-44 h-44 sm:w-48 sm:h-48 lg:w-52 lg:h-52 border-4 border-yellow-500 rounded-full 
              bg-opacity-20 bg-secondary"
            />
            <div className="flex flex-col gap-2 h-3/4 w-[200px] items-center lg:items-start lg:text-left">
              <div className="flex gap-2">
                <Heading white>{teamData.name}</Heading>
                <Heading gray>{teamData.region}</Heading>
              </div>
              <div className="flex items-center gap-2">
                <Paragraph white>
                  {teamData.accepting_requests
                    ? "Reclutando"
                    : "Reclutando"}
                </Paragraph>
                {teamData.accepting_requests ? (
                  <AiFillCheckCircle size={20} className="text-green-500" />
                ) : (
                  <AiFillCloseCircle size={20} className="text-red-500" />
                )}
              </div>
              <div>
                <div className="text-white">
                  Creado el{" "}
                  {new Date(teamData.created_date).toISOString().split("T")[0]}
                </div>
              </div>
            </div>
            <div className="text-white w-[200px] h-fit overflow-hidden">
              <p style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                {teamData.description}
              </p>
            </div>
            <div className="text-white  lg:w-1/4 self-start sm:self-center w-[200px] justify-center text-center">
              <div className="flex items-center gap-2 justify-center ">
                <div>Verificado:</div>
                {teamData.verified ? (
                  <AiFillCheckCircle size={20} className="text-green-500" />
                ) : (
                  <AiFillCloseCircle size={20} className="text-red-500" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPageCard;
