import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../components/Loader"; 

const BanSummonerSpellModal = ({ tournamentId, closeModal }) => {
  const [summonerSpells, setSummonerSpells] = useState([]);
  const [selectedSpell, setSelectedSpell] = useState("");
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [bannedSpells, setBannedSpells] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseModalContent, setResponseModalContent] = useState("");
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoadingInitial(true);
        const bannedSpellsResponse = await axios.get(
          `api/tournament/${tournamentId}/rules`
        );

        const bannedSpellsData = bannedSpellsResponse.data.filter(
          (item) => item.banned && typeof item.banned === "string"
        );

        const bannedSpellsNames = bannedSpellsData.map(
          (bannedItem) => bannedItem.banned
        );

        setBannedSpells(bannedSpellsNames);

        const summonerSpellList = [
          {
            name: "Ghost",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerHaste.png",
          },
          {
            name: "Heal",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerHeal.png",
          },
          {
            name: "Exhaust",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerExhaust.png",
          },
          {
            name: "Ignite",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerDot.png",
          },
          {
            name: "Barrier",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerBarrier.png",
          },
          {
            name: "Teleport",
            imageUrl:
            "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerTeleport.png",
          },
        ];

        const filteredSpells = summonerSpellList.filter(
          (spell) => !bannedSpellsNames.includes(spell.name)
        );

        setSummonerSpells(filteredSpells);
        setIsLoadingInitial(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [tournamentId]);

  const handleBanSummonerSpell = async (spellName) => {
    try {
      setIsLoadingAction(true);

      const response = await axios.post("tournament/exchange/rule-ticket/", {
        tournament_id: tournamentId,
        ban_type: "hechizo",
        ban_name: spellName,
      });
      setResponseModalContent(response.data.detail);
      setIsLoadingAction(false);
      setShowResponseModal(true);
      setSelectedSpell("");
    } catch (error) {
      setIsLoadingAction(false);
      setResponseModalContent(
        error.response?.data?.detail || "Error al banear Summoner Spell"
      );
      setShowResponseModal(true);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const closeModalHandler = () => {
    setShowResponseModal(false);
    setResponseModalContent("");
    closeModal();
    window.location.reload()
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="modal-content bg-fifth p-4 rounded-xl">
        <h2 className="text-white font-bold text-xl text-center mb-4">
          Selecciona un Hechizo de Invocador para Banear
        </h2>
        {isLoadingInitial ? (
          <div className="flex items-center justify-center w-full h-full my-4">
            <Loader />
          </div>
        ) : (
          <div className="flex justify-center items-center flex-wrap gap-4">
            {summonerSpells.map((spell, index) => (
              <button
                key={index}
                onClick={() => setSelectedSpell(spell.name)}
                className={`flex items-center justify-center border border-transparent rounded-md p-2 mb-2
                  ${
                    selectedSpell === spell.name
                      ? "bg-yellow-500 text-white"
                      : "bg-gray-500 text-white"
                  }
                `}
                style={{ width: "120px", height: "60px" }}
              >
                <img
                  src={spell.imageUrl}
                  alt={spell.name}
                  className="w-8 h-8 mr-2"
                />
                {spell.name}
              </button>
            ))}
          </div>
        )}
        <div className="flex space-x-4 justify-center items-center mx-4">
          <button
            className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
            onClick={() => handleBanSummonerSpell(selectedSpell)}
            disabled={!selectedSpell}
          >
            Banear
          </button>
          <button
            className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
            onClick={closeModal}
          >
            Cancelar
          </button>
        </div>
        {showResponseModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
              <h3 className="font-bold text-lg">Nueva Regla de Torneo</h3>
              <p>{responseModalContent}</p>
              <button
                onClick={closeModalHandler}
                className="text-xs sm:text-base bg-yellow-500 text-white px-3 sm:px-4 py-2 sm:py-2 rounded-md mt-4"
              >
                Cerrar
              </button>
            </div>
          </div>
        )}
        {isLoadingAction && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default BanSummonerSpellModal;
