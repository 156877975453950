import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/Loader";

const ModalRules = ({ tournamentId, closeModal }) => {
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentVersion, setCurrentVersion] = useState("");
  const [bannedImages, setBannedImages] = useState({});

  const fetchRules = async () => {
    try {
      const versionResponse = await axios.get(
        "https://ddragon.leagueoflegends.com/api/versions.json"
      );
      const versions = versionResponse.data;
      const firstVersion = versions[0];
      setCurrentVersion(firstVersion);

      const rulesResponse = await axios.get(
        `api/tournament/${tournamentId}/rules`
      );
      setRules(rulesResponse.data);
    } catch (error) {
      console.error("Error al obtener reglas:", error);
      setRules([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchBannedImages = async () => {
    const bannedImagesMap = {};

    for (const rule of rules) {
      const isChampion = rule.rule_text.toLowerCase().includes("campeón");
      const isSpell = rule.rule_text.toLowerCase().includes("hechizo");
      const isItem = rule.rule_text.toLowerCase().includes("ítem");

      if (isChampion || isSpell || isItem) {
        const imageUrl = await getBannedImageUrl(
          rule,
          isChampion,
          isSpell,
          isItem
        );
        bannedImagesMap[rule.id] = imageUrl;
      }
    }

    setBannedImages(bannedImagesMap);
  };

  const getBannedImageUrl = async (rule, isChampion, isSpell, isItem) => {
    const id = rule.banned;

    if (isChampion || isSpell) {
      return await getImageUrl(isChampion, id);
    } else if (isItem) {
      return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/item/${id}.png`;
    }
    return "";
  };

  const getImageUrl = async (isChampion, id) => {
    const dataType = isChampion ? "champion" : "summoner";
    const response = await axios.get(
      `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/data/en_US/${dataType}.json`
    );
    const data = response.data.data;

    if (isChampion) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const champion = data[key];
          if (champion.id.toLowerCase() === id.toLowerCase()) {
            return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/${dataType}/${champion.image.full}`;
          }
        }
      }
    } else {
      for (const summonerSpell in data) {
        if (data.hasOwnProperty(summonerSpell)) {
          const spell = data[summonerSpell];
          if (spell.name.toLowerCase() === id.toLowerCase()) {
            return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/spell/${spell.image.full}`;
          }
        }
      }
    }

    return "";
  };

  useEffect(() => {
    fetchRules();
  }, [tournamentId]);

  useEffect(() => {
    if (currentVersion && rules.length > 0) {
      fetchBannedImages();
    }
  }, [currentVersion, rules]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center w-full md:w-5/12	 lg:w-5/12	 h-5/6 overflow-auto">
        <h3 className="font-bold text-lg mb-4">Lista de Reglas</h3>

        <div className="modal-content w-full h-full overflow-auto scrollbar-thin scrollbar-thumb-yellow-700 scrollbar-track-blue-300  scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
          {loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : (
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-yellow-500 font-bold text-center">
                    Usuario
                  </th>
                  <th className="text-yellow-500 font-bold text-center hidden lg:table-cell">
                    Regla
                  </th>
                  <th className="text-yellow-500 font-bold text-center">
                    Banned
                  </th>
                  <th className="text-yellow-500 font-bold text-center hidden lg:table-cell">
                    Tier
                  </th>
                  <th className="text-yellow-500 font-bold text-center">
                    Activo
                  </th>
                </tr>
              </thead>
              <tbody>
                {rules.map((rule) => (
                  <tr key={rule.id}>
                    <td className="text-left flex flex-1 items-center w-auto gap-2">
                      <img
                        src={`https://cdn.discordapp.com/avatars/${rule.user_id}/${rule.user_avatar}.png`}
                        alt="Discord Avatar"
                        className="rounded-full w-8 h-8 sm:w-10 sm:h-10 bg-gray-400 max-w-none bg-opacity-30"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
                        }}
                      />
                      {rule.user_discord_tag.charAt(0).toUpperCase()}
                      {rule.user_discord_tag.slice(1)}
                    </td>
                    <td className="w-auto flex-1 text-center hidden lg:table-cell">
                      {rule.rule_text}
                    </td>
                    <td className="w-auto flex-1 text-center flex justify-center self-center">
                      {bannedImages[rule.id] ? (
                        <img
                          src={bannedImages[rule.id]}
                          alt="Banned"
                          className="rounded-full w-8 h-8 sm:w-10 sm:h-10 bg-gray-400 max-w-none bg-opacity-30"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://i.redd.it/j3t4cvgywd051.png";
                          }}
                        />
                      ) : (
                        <span>No Image</span>
                      )}
                    </td>
                    <td className="w-auto flex-1 text-center hidden lg:table-cell">
                      {rule.tier}
                    </td>
                    <td className="w-auto text-center place-self-centerr">
                      {rule.is_active ? (
                        <span className="text-green-500">✓</span>
                      ) : (
                        <span className="text-red-500">✕</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <button
          onClick={closeModal}
          className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ModalRules;
