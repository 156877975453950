import React from "react";
import { useNavigate } from "react-router-dom";
const NotificationsTeam = ({ notifications }) => {
  const navigate = useNavigate();
  const sortedAndFilteredNotifications = notifications.slice(0, 20);

  return (
    <div className="bg-fifth border rounded shadow-lg text-white flex flex-col gap-2 items-center
    max-h-max p-2 ">
      {sortedAndFilteredNotifications.length === 0 ? (
        <div className="px-1 py-1 text-sm">
          No tienes ninguna invitacion todavia.
        </div>
      ) : (
        sortedAndFilteredNotifications.map((notification) => (
          <div
            key={notification.id}
            className="px-0.5 py-0.5 fill-hover border-b flex justify-between items-center w-full"
          >
            <div
              onClick={() => navigate("/invitations")}
              className="flex items-center cursor-pointer"
            >
              <div className="text-sm">
                {notification.team} te ha invitado a su equipo.
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default NotificationsTeam;