import React, { useState, useEffect } from "react";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { FaUserFriends } from "react-icons/fa";
import axios from "axios";
import Loader from "../../components/Loader";
import Images from "../../components/ImagesContainer";
import { useNavigate } from "react-router-dom";
import RegionSelection from "./RegionSelection";
import ReceiveRequestsSelection from "./ReceiveRequestsSelection";

const CreateTeam = () => {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    teamName: "",
    region: "LAN",
    recruiting: false,
    image: "",
    teamDescription: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [error, setError] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedTeamImage, setSelectedTeamImage] = useState("");
  const [selectedModalImage, setSelectedModalImage] = useState(null);
  const [teamDescription, setTeamDescription] = useState("");
  const [descriptionLength, setDescriptionLength] = useState(0);

  useEffect(() => {
    if (submittedData || error) {
      setShowModal(true);
    }
  }, [submittedData, error]);

  const closeModal = () => {
    setShowModal(false);
    if (submittedData) {
      navigate(`/league/equipos/${formData.teamName}`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (name === "recruiting" && type === "select-one") {
      setFormData((prev) => ({
        ...prev,
        [name]: value === "Yes",
      }));
    } else if (name === "teamDescription") {
      if (value.length <= 144) {
        setTeamDescription(value);
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setDescriptionLength(value.length);
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedData(null);
    setError(null);
    setLoading(true);

    const trimmedTeamName = formData.teamName.trim();

    const updatedFormData = { ...formData, teamName: trimmedTeamName };

    axios
      .post("/api/create-team/", updatedFormData)
      .then((response) => {
        setSubmittedData(response.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setError(error.response.data.detail);
        } else {
          setError("Ocurrió un error al procesar la solicitud.");
        }
        console.error("Error al enviar la solicitud:", error);
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  const handleImageClick = (imageName) => {
    setFormData((prev) => ({
      ...prev,
      image: imageName,
    }));
    setSelectedTeamImage(imageName);
    setSelectedModalImage(imageName); 
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  const isFormComplete =
    formData.teamName &&
    formData.image &&
    formData.region &&
    teamDescription.length <= 144;
  const image = "https://i.redd.it/j3t4cvgywd051.png";

  return (
    <Container>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col gap-2 mt-2">
        <TitleContainer
          text="Crea tu equipo"
          icon={<FaUserFriends />}
          color="--fifth-color"
          flex="end"
        />

        <div className="bg-secondary p-8 rounded">
          <div className="text-center font-bold text-5xl text-white my-8">
            Crea tu equipo
          </div>

          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center gap-2"
          >
            <div className="text-center text-white font-semibold mb-2">
              Selecciona una imagen
            </div>
            <div
              onClick={() => setIsImageModalOpen(true)}
              className="flex items-center justify-center mb-4 "
            >
              <div className="h-44 w-44 rounded-full overflow-hidden bg-black bg-opacity-50 items-center justify-center relative">
                <div className="image-overlay h-44 w-44 rounded">
                  <img
                    src={Images[selectedTeamImage] || image}
                    alt="Avatar de Discord"
                    className="w-full h-full object-cover border-4 border-yellow-500 rounded-full cursor-pointer"
                  />
                  <div className="placeholder-text text-center">
                    Selecciona tu imagen
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center font-semibold text-lg text-white my-2">
              <input
                type="text"
                id="teamName"
                name="teamName"
                placeholder="Nombre del equipo"
                value={formData.teamName}
                onChange={handleInputChange}
                required
                className="relative bg-gray-900 bg-opacity-25 ring-0 outline-none border border-white text-white placeholder-gray-400 text-sm rounded-lg focus:yellow placeholder-opacity-60 focus:border-yellow-500 block w-full p-2.5 checked:bg-emerald-500"
              />
            </div>
            <div className="text-center font-semibold text-lg text-white ">
              <textarea
                id="teamDescription"
                name="teamDescription"
                placeholder="Descripción del equipo (máximo 144 caracteres)"
                value={teamDescription}
                onChange={handleInputChange}
                className="relative bg-gray-900 bg-opacity-25 ring-0 outline-none border border-white text-white placeholder-gray-400 text-sm rounded-lg focus:yellow placeholder-opacity-60 focus:border-yellow-500 block w-full p-2.5 checked:bg-emerald-500 "
              />
              <div className="text-gray-300 text-sm">
                {descriptionLength}/144 caracteres
              </div>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2  grid-cols-2 gap-4">
              <RegionSelection formData={formData} setFormData={setFormData} />
              <ReceiveRequestsSelection
                receiveOffers={formData.recruiting}
                setReceiveOffers={(value) =>
                  setFormData({ ...formData, recruiting: value })
                }
              />
            </div>
            <button
              type="submit"
              className={`modal-button bg-gray rounded-md text-white font-bold text-xs py-2 px-4 border-2 sm:text-base ${
                isFormComplete
                  ? "bg-primary border-transparent hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                  : "bg-gray-300 text-gray-600 cursor-not-allowed"
              }`}
              disabled={!isFormComplete}
            >
              Crear Equipo
            </button>
          </form>
        </div>
      </div>
      {isImageModalOpen && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
          <div className="modal-content bg-fifth p-4 rounded-xl">
            <div className="mb-4 text-white font-bold text-xl text-center">
              Selecciona una imagen para tu equipo
            </div>
            <div className="overflow-y-scroll max-h-60 md:max-h-96 p-2 scrollbar-thin scrollbar-thumb-yellow-700 scrollbar-track-blue-300  scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              <div className="grid grid-cols-3 md:grid-cols-5 gap-2 md:gap-4">
                {Object.keys(Images).map((key) => (
                  <div
                    key={key}
                    className={`cursor-pointer relative text-center ${
                      key === selectedModalImage
                        ? "border-gradient-yellow border-2 boder-opacity-50 rounded-lg"
                        : "border-2 border-transparent rounded-lg"
                    }`}
                    onClick={() => handleImageClick(key)}
                  >
                    <img
                      src={Images[key]}
                      alt={key}
                      className="w-full h-auto md:h-36 object-contain"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="text-center mt-4">
              <button
                className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                onClick={closeImageModal}
              >
                Seleccionar
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
          <h3 className="font-bold text-2xl mb-4">Creacion del equipo</h3>
            <p>{submittedData ? submittedData.detail : error}</p>
            <button
              onClick={closeModal}
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
              >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CreateTeam;
