import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import { TbSwords } from "react-icons/tb";
import Loader from "../../components/Loader";
import CardsHeading from "../../components/CardsHeading";
import MyInvScrimCard from "./MyInvScrimCard";
const ScrimInvs = ({ userData }) => {
  const [invitations, setInvitations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isWorking, setIsWorking] = useState(false);

  const fetchInvitations = useCallback(() => {
    setIsLoading(true);
    Axios.get("/api/my-scrims-invitations/")
      .then((response) => {
        setInvitations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching scrim invitations:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isCaptainOrSubcaptain = () =>
    userData &&
    userData.team_info &&
    (userData.team_info.is_captain || userData.team_info.is_subcaptain);

  const handleAccept = (inviteId) => {
    setIsWorking(true);
    setIsLoading(true);
    Axios.post("/api/accept-scrim-invitation/", { id: inviteId })
      .then(() => {
        setInvitations((prev) => prev.filter((inv) => inv.id !== inviteId));
        fetchInvitations();
        setIsWorking(false);
      })
      .catch((error) => {
        console.error("Error accepting scrim invitation:", error);
        fetchInvitations();
        setIsWorking(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleReject = (inviteId) => {
    setIsWorking(true);
    setIsLoading(true);
    Axios.post("/api/reject-scrim-invitation/", { id: inviteId })
      .then(() => {
        setInvitations((prev) => prev.filter((inv) => inv.id !== inviteId));
        setIsWorking(false);
        fetchInvitations();
      })
      .catch((error) => {
        console.error("Error rejecting scrim invitation:", error);
        setIsWorking(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getTitles = () => {
    if (width < 640) {
      if (isCaptainOrSubcaptain()) {
        return ["Equipo", "Elo Promedio", "Comentarios", "Accion"];
      }
      return ["Equipo", "Elo Promedio", "Comentarios"];
    }
    return isCaptainOrSubcaptain()
      ? ["Equipo", "Elo Promedio", "Comentarios", "Accion"]
      : ["Equipo", "Elo Promedio", "Comentarios"];
  };

  return (
    <Container>
      {isLoading || userData === undefined ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-4">
          <TitleContainer
            text={"Invitaciones para scrims recibidas"}
            icon={<TbSwords />}
            color="--primary-color"
            flex="start"
          />
          <div className="flex flex-col gap-2">
            <CardsHeading titles={getTitles()} />
            {invitations.map((invite, index) => (
              <div key={index}>
                <MyInvScrimCard
                  scrim={invite}
                  handleAcceptClick={() => handleAccept(invite.id)}
                  handleCancelClick={() => handleReject(invite.id)}
                  isCaptain={isCaptainOrSubcaptain}
                  isWorking={isWorking}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default ScrimInvs;
