import React from "react";
import bg from "../../assets/background.webp";
import Heading from "../../components/Heading";
import Discord from "./Discord";

const index = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.9) 100%), url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
      className="
    flex
    items-center
    justify-center
    w-full
    "
    >
      <div
        className="
        w-52 h-52
        sm:w-56 sm:h-64
        md:w-64 md:h-72
        lg:w-72 lg:h-72
        xl:w-80 xl:h-96
        bg-gray-700
        flex
        flex-col
        items-center
        justify-center
        gap-2
        sm:gap-3
        md:gap-4
        lg:gap-5
        xl:gap-6
        "
      >
        <div className="flex flex-col gap-1 justify-center items-center">
          <Heading white large>
            Welcome to
          </Heading>
          <Heading white large>
            Autoboost
          </Heading>
        </div>

        <Heading white>Sign In</Heading>
        <Discord />
      </div>
    </div>
  );
};

export default index;
