import React from "react";
import ActionButton from '../../components/ActionButton'
const ScrimModal = ({ title, isOpen, onClose, children, onClick }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto top-10">
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="modal-bg fixed inset-0 bg-gray-500 bg-opacity-75"
          onClick={onClose}
        ></div>
        <div
          className="modal-content bg-fifth rounded-lg p-6 mx-auto relative w-2/3
        flex flex-col items-center gap-2"
        >
          <h2 className="text-2xl mb-4 text-white">{title}</h2>
          {children}
          <button onClick={onClose} className="absolute top-2 right-2 text-xl">
            ×
          </button>
          <ActionButton primary onClick={onClick}>Enviar Invitacion</ActionButton>
        </div>
        
      </div>
    </div>
  );
};

export default ScrimModal;
