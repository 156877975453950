import React from "react";
import { useNavigate } from "react-router-dom";
const NavLink = ({ link, children }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(link)}
      className="relative group hover:text-yellow-400 cursor-pointer"
    >
      {children}
      <span className="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-400 transform scale-x-0 group-hover:scale-x-100 transition-transform"></span>
    </div>
  );
};

export default NavLink;
