import React, { useState } from "react";
import BOTH from "../../assets/icons8-america-96.png";
import LAN from "../../assets/icons8-america-96.png";
import LAS from "../../assets/icons8-south-america-96.png";

const Region = ({ setRegion, region }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const selectRegion = (region) => {
    setRegion(region.name);
  };

  const regions = [
    { id: "LAN/LAS", name: "LAN/LAS", icon: BOTH },
    { id: "LAN", name: "LAN", icon: LAN },
    { id: "LAS", name: "LAS", icon: LAS },
  ];

  return (
    <div className="relative">
      <div
        className="p-4 relative inset-0 flex flex-col items-center cursor-pointer"
        onClick={openModal}
      >
        <div className="bg-black bg-opacity-50 p-4 rounded  w-36 md:w-50 lg:w-60 h-36 md:h-50 lg:h-60 marker:cursor-pointer flex flex-col items-center justify-center text-center">
          <img
            src={regions.find((r) => r.name === region)?.icon || regions[0].icon}
            alt={region || regions[0].name}
            width={60}
            height={60}
            className="mb-2"
          />
          <span className="mb-2 text-white lg:text-2xl flex items-center">
            {region || "Selecciona una región"}
          </span>
        </div>
      </div>
      {isModalVisible && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
          <div className="modal-content bg-fifth p-4 rounded-xl">
            <div className="mb-4 text-white font-bold text-lg text-center">
              Seleccionar región
            </div>
            <div className="regions-modal grid grid-cols-3 gap-8">
              {regions.map((r) => (
                <div
                  key={r.id}
                  className={`region-item cursor-pointer relative text-center ${
                    region === r.name ? "opacity-100" : "opacity-50"
                  }`}
                  onClick={() => selectRegion(r)}
                >
                  <img
                    src={r.icon}
                    alt={r.name}
                    width={40}
                    height={40}
                    className="mx-auto"
                  />
                  <div className={`mb-2 text-white ${region === r.name ? "text-yellow-500" : ""}`}>
                    {r.name}
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-4">
              <button
                className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                onClick={closeModal}
              >
                Seleccionar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Region;
