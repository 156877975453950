import React, { useState, useEffect } from "react";
import { BsQuestionCircle } from "react-icons/bs";

const SelectGame = ({ selectedGame, games, setGame }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState(selectedGame || null);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const selectGame = (game) => {
    if (game.enabled) {
      setSelectedGameId(game.id);
    }
  };

  useEffect(() => {
    if (selectedGame) {
      setSelectedGameId(selectedGame);
    }
  }, [selectedGame]);

  return (
    <div className="relative">
      <div
        className="p-4 relative inset-0 flex flex-col items-center cursor-pointer"
        onClick={openModal}
      >
        <div className="bg-black bg-opacity-50 p-4 rounded w-36 md:w-50 lg:w-60 h-36 md:h-50 lg:h-60 marker:cursor-pointer flex flex-col items-center justify-center text-center">
          {selectedGameId ? (
            <img
              src={games.find((game) => game.id === selectedGameId).logo}
              alt={games.find((game) => game.id === selectedGameId).name}
              width={60}
              height={60}
              className="mb-2"
            />
          ) : (
            <div>
              <BsQuestionCircle size={40} className="text-white mb-2" />
            </div>
          )}
          <span className="mb-2 text-white lg:text-2xl flex items-center">
            {selectedGameId
              ? games.find((game) => game.id === selectedGameId).name
              : "Selecciona un juego"}
          </span>
        </div>
      </div>
      {isModalVisible && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
          <div className="modal-content bg-fifth p-4 rounded-xl">
            <div className="mb-4 text-white font-bold text-lg text-center">
              Seleccionar juego
            </div>
            <div className="games-modal grid grid-cols-3 gap-8">
              {games.map((game) => (
                <div
                  key={game.id}
                  className={`game-item cursor-pointer relative text-center ${
                    game.enabled
                      ? selectedGameId === game.id
                        ? "opacity-100"
                        : "opacity-50"
                      : "opacity-30 pointer-events-none"
                  }`}
                  onClick={() => selectGame(game)}
                >
                  <img
                    src={game.logo}
                    alt={game.name}
                    className="game-image h-40"
                  />
                  <div className="game-name text-white text-center">
                    {game.enabled ? game.name : "Coming Soon"}
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-4">
              <button
                className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                onClick={closeModal}
              >
                Seleccionar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectGame;
