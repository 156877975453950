import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ children, primary, disabled, link, customStyle }) => {
  const navigate = useNavigate();
  const color = primary
    ? "modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
    : "modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60";
  const text = primary ? "text-white" : "text-white";
  const buttonStyles = customStyle || "";
  return (
    <button
      onClick={() => !disabled && navigate(link)}
      className={`${color} rounded-md ${text} font-bold text-xs ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      } py-2 px-4 text-sm sm:text-base ${buttonStyles}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
