import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import TitleContainer from "../../components/TitleContainer";
import { RiTeamFill } from "react-icons/ri";
import Heading from "../../components/Heading";
import { PlayerCard } from "../../components/PlayerCard";
import {
  BOTH,
  LAN,
  LAS,

} from "../profile/AssetsContainer";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  iron,
  bronce,
  top,
  jungler,
  mid,
  adc,
  support,
} from "../../components/AssetsBarrel";
import TeamPageCard from "./TeamPageCard";

const TeamPage = ({ user, isUserLoggedIn, userData }) => {
  const { teamName } = useParams();
  const [teamData, setTeamData] = useState(null);
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [teamRequests, setTeamRequests] = useState([]);
  const [joined, setJoined] = useState(null);
  const [requestStatus, setRequestStatus] = useState(null);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showTeamManagerModal, setShowTeamManagerModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null); 
  const [showRoleUpdateModal, setShowRoleUpdateModal] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isToggling, setIsToggling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const navigate = useNavigate();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const fetchTeamData = async () => {
    try {
      const response = await axios.get(`/api/team/${teamName}`);
      setTeamData(response.data);
    } catch (error) {
      console.error("Error fetching team data", error);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, [teamName]);

  const checkPendingRequest = async () => {
    try {
      const response = await axios.get("/team-requests/pending/me/");

      const pendingRequest = response.data.find(
        (request) => request.team === teamData.name
      );

      if (pendingRequest) {
        setRequestStatus("pending");
      }
    } catch (error) {
      console.error("Error checking for pending requests", error);
    }
  };

  useEffect(() => {
    if (teamData && user && user.has_profile) {
      checkPendingRequest();
    }
  }, [teamData, user]);

  const toggleTeamRequests = async () => {
    setIsToggling(true);
    try {
      const response = await axios.post("/api/update-team-requests/");
      if (response.status === 200) {
        setTeamData((prevData) => ({
          ...prevData,
          accepting_requests: !prevData.accepting_requests,
        }));
      }
    } catch (error) {
      console.error("Error toggling team requests", error);
    } finally {
      setIsToggling(false);
    }
  };
  const deleteTeam = async () => {
    setIsDeleting(true);
    try {
      const response = await axios.post("/api/remove-team-capitan/");
      if (response.status === 200) {
        navigate("/league/equipos");
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error(error.response.data.detail);
      } else {
        console.error("Error deleting the team", error);
      }
    } finally {
      setIsDeleting(false);
      window.location.reload();
    }
  };

  const getRegionIcon = (region) => {
    switch (region) {
      case "LAN":
        return LAN;
      case "LAS":
        return LAS;
      case "BOTH":
        return BOTH;
      default:
        return null;
    }
  };

  const renderPlayerCards = () => {
    if (!teamData || !teamData.members) {
      return null;
    }

    const members = teamData.members;

    const captain = teamData.captain && teamData.captain[0];
    const subcaptains = teamData.subcaptains;

    return members.map((member, index) => {
      const memberType = getPlayerType(
        member.discord_tag,
        captain,
        subcaptains,
        teamData.staff
      );

      return (
        <PlayerCard
          key={index}
          member={memberType}
          name={member.discord_tag}
          role={member.role}
          region={member.region}
          soloq_account={member.soloq_account__summonerName}
          position={index + 1}
          elo={`${member.soloq_account__tier} ${member.soloq_account__rank}`}
          tier={member.soloq_account__tier}
          rank={member.soloq_account__rank}
          lp={member.soloq_account__leaguePoints}
          active={member.is_active}
          summoner={member.soloq_account__summonerName}
          user_id={member.user_id}
          avatar_id={member.avatar_id}
          coins={member.coins}
        />
      );
    });
  };

  const getPlayerType = (discordTag, captain, subcaptains, staff) => {
    if (discordTag === captain) {
      return "captain";
    } else if (subcaptains.includes(discordTag)) {
      return "subcaptain";
    } else if (staff.includes(discordTag)) {
      return "staff";
    } else {
      return "player";
    }
  };

  const joinTeam = async () => {
    try {
      setIsJoining(true);
      const requestData = {
        team_name: teamName, 
      };

      const response = await axios.post("/team-requests/create/", requestData);

      setTeamData({ ...teamData, requestSent: true });
      setRequestStatus("pending"); 
    } catch (error) {
      console.error("Error al crear la solicitud de equipo", error);
    } finally {
      setIsJoining(false); 
    }
  };

  const openTeamManagerModal = () => {
    setShowTeamManagerModal(true);
  };

  const closeTeamManagerModal = () => {
    setShowTeamManagerModal(false);
  };
  const openRoleUpdateModal = (member) => {
    setSelectedMember(member); 
    setShowRoleUpdateModal(true); 
    setShowTeamManagerModal(false);
  };

  const closeRoleUpdateModal = () => {
    setSelectedMember(null); 
    setShowRoleUpdateModal(false);
  };
  const openRequestsModal = async () => {
    try {
      const response = await axios.get(`/team-requests/pending/my-team/`);
      setTeamRequests(response.data); 
      setShowRequestsModal(true); 
    } catch (error) {
      console.error("Error al obtener las solicitudes pendientes", error);
    }
  };

  const handleAcceptRequest = async (requestId) => {
    setIsProcessing(true);
    try {
      const response = await axios.post(`/team-requests/accept/`, {
        request_id: requestId, 
      });

      if (response.status === 200) {
        fetchTeamData();
        const updatedRequests = teamRequests.filter(
          (request) => request.id !== requestId
        );
        setTeamRequests(updatedRequests);
      }
    } catch (error) {
      console.error("Error al aceptar la solicitud de equipo", error);
    } finally {
      setIsProcessing(false); 
    }
  };
  const handleRejectRequest = async (requestId) => {
    setIsProcessing(true);
    try {
      const response = await axios.post(`/team-requests/reject/`, {
        request_id: requestId, 
      });

      if (response.status === 200) {

        const updatedRequests = teamRequests.filter(
          (request) => request.id !== requestId
        );
        setTeamRequests(updatedRequests);
      }
    } catch (error) {
      console.error("Error al aceptar la solicitud de equipo", error);
    } finally {
      setIsProcessing(false); 
    }
  };
  const leaveTeam = async () => {
    setIsProcessing(true); 
    try {
      const response = await axios.post("/team/leave/");

      if (response.status === 200) {
      }
      setJoined(false);
    } catch (error) {
      console.error("Error al abandonar el equipo", error);
    } finally {
      setIsProcessing(false); 
    }
  };

  const isUserCaptainOrSubcaptain = () => {
    const member = teamData.members.find((m) => m.id === user.user_profile.id);

    if (!member) return false;

    const discordTag = member.discord_tag;

    return (
      teamData.captain.includes(discordTag) ||
      teamData.subcaptains.includes(discordTag)
    );
  };
  const isUserCaptain = () => {
    const member = teamData.members.find((m) => m.id === user.user_profile.id);

    if (!member) return false;

    const discordTag = member.discord_tag;

    return teamData.captain.includes(discordTag);
  };

  const isUserAMember = () => {
    return teamData.members.some((m) => m.id === user.user_profile.id);
  };
  useEffect(() => {
    if (requestStatus === "pending" && isUserAMember()) {
      setRequestStatus("accepted"); 
    }
  }, [requestStatus]); 

  const confirmLeave = async () => {
    await leaveTeam(); 
    setShowLeaveModal(false); 
    window.location.reload();
  };

  const cancelLeave = () => {
    setShowLeaveModal(false);
  };

  const removeMember = async (memberId) => {
    setIsRemoving(true); 

    try {
      const response = await axios.post("/team/remove-member/", {
        user_id: memberId,
      });

      if (response.status === 200) {
        const updatedMembers = teamData.members.filter(
          (member) => member.id !== memberId
        );
        setTeamData({ ...teamData, members: updatedMembers });
      }
    } catch (error) {
      console.error("Error removing the team member", error);
    } finally {
      setIsRemoving(false); 
      window.location.reload();
    }
  };

  const updateMemberRole = async (newRole) => {
    setIsLoading(true);

    try {
      const response = await axios.post("/team/edit-member-role/", {
        user_id: selectedMember.id,
        new_role: newRole,
      });

      if (response.status === 200) {
        console.log(response.data.detail);
      }
      setIsLoading(false);
      closeRoleUpdateModal(); 
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating the member role", error);
    } finally {
      fetchTeamData();
    }
  };
  const getRankImage = (tier) => {
    switch (tier) {
      case "CHALLENGER":
        return challenger;
      case "GRANDMASTER":
        return grandmaster;
      case "MASTER":
        return master;
      case "DIAMOND":
        return diamond;
      case "EMERALD":
        return emerald;
      case "PLATINUM":
        return platinum;
      case "GOLD":
        return gold;
      case "SILVER":
        return silver;
      case "BRONCE":
        return bronce;
      case "IRON":
        return iron;
      default:
        return null;
    }
  };

  const getRoleImage = (role) => {
    switch (role) {
      case "Top":
        return top;
      case "Jungler":
        return jungler;
      case "Mid":
        return mid;
      case "ADC":
        return adc;
      case "Support":
        return support;
      default:
        return null;
    }
  };

  return (
    <Container>
      {teamData ? (
        <div className="flex flex-col gap-2 mt-4">
          <TitleContainer
            text={teamData.name}
            icon={<RiTeamFill />}
            color="--primary-color"
            flex="start"
          />
          <TeamPageCard teamData={teamData} />
          {isUserLoggedIn && user && user.has_profile ? (
            <div className="flex gap-2 justify-start items-center w-full">
              {isJoining ? (
                <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 flex justify-center items-center z-50">
                  <Loader />
                </div>
              ) : requestStatus === null &&
                teamData.accepting_requests &&
                !userData.in_team &&
                !isUserAMember() ? (
                <div className="flex justify-center items-center w-26 h-12">
                  <button
                    onClick={joinTeam}
                    className="bg-primary text-white p-1 rounded
                    text-xs sm:text-sm"
                  >
                    Unirse
                  </button>
                </div>
              ) : requestStatus === "pending" ? (
                <div className="text-yellow-500">Solicitud pendiente...</div>
              ) : null}
              {isUserCaptainOrSubcaptain() && (
                <div className="flex justify-center items-center w-26 h-12">
                  <button
                    onClick={openRequestsModal}
                    className="bg-primary text-white p-1 rounded mt-4
                    text-xs sm:text-sm"
                  >
                    Ver Solicitudes
                  </button>
                </div>
              )}
              {isUserCaptain() && (
                <div className="flex justify-center items-center w-26 h-12">
                  <button
                    onClick={openTeamManagerModal}
                    className="bg-primary text-white p-1 rounded mt-4
                    text-xs sm:text-sm"
                  >
                    Configurar miembros
                  </button>
                </div>
              )}
              {isUserCaptain() && (
                <div className="flex justify-center items-center w-26 h-12">
                  <button
                    onClick={toggleTeamRequests}
                    className={`text-white p-1 rounded mt-4 ${
                      teamData.accepting_requests
                        ? "bg-green-500 hover:bg-green-700"
                        : "bg-red-500 hover:bg-red-700"
                    }text-xs sm:text-sm`}
                    disabled={isToggling}
                  >
                    {teamData.accepting_requests ? "Abierto" : "Cerrado"}
                  </button>
                </div>
              )}

              {isUserCaptain() && teamData.members.length === 1 && (
                <div className="flex justify-center items-center w-26 h-12">
                  <button
                    onClick={() => setShowDeleteConfirmationModal(true)}
                    className="bg-red-500 text-white p-1 rounded mt-4
                    text-xs sm:text-sm"
                    disabled={isDeleting}
                  >
                    {isDeleting ? "Eliminando..." : "Eliminar Equipo"}
                  </button>
                </div>
              )}

              {isUserAMember() && !isUserCaptain() && (
                <div className="flex justify-center items-center w-26 h-12">
                  <button
                    onClick={() => setShowLeaveModal(true)}
                    className="bg-primary text-white p-1 rounded mt-4
                    text-xs sm:text-sm"
                  >
                    Leave
                  </button>
                </div>
              )}
            </div>
          ) : null}
          <div className="flex flex-col gap-2">{renderPlayerCards()}</div>

          <Modal
            isOpen={showRoleUpdateModal}
            onRequestClose={closeRoleUpdateModal}
            style={{
              overlay: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)", 
              },
              content: {
                position: "relative",
                top: "auto",
                right: "auto",
                bottom: "auto",
                left: "auto",
                maxHeight: "90vh",  
                overflowY: "auto",
              },
            }}
          >
            {isLoading && (
              <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 flex justify-center items-center z-50">
                <Loader />
              </div>
            )}

            <div className="flex flex-col items-center p-4 justify-center relative z-0">
              <Heading medium>Actualizar Rango</Heading>

              {selectedMember && (
                <div className="mt-4 w-full">
                  <p>Actualizar el rango de {selectedMember.discord_tag}</p>
                  {teamData.subcaptains.includes(selectedMember.discord_tag) ? (
                    <>
                      <button
                        onClick={() => updateMemberRole("miembro")}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 focus:outline-none focus:border-red-900 focus:shadow-outline-red active:bg-red-800"
                      >
                        Delegar a miembro
                      </button>
                      <button
                        onClick={() => updateMemberRole("capitán")}
                        className="bg-green-700 text-white px-4 py-2 rounded hover:bg-green-700 focus:outline-none focus:border-green-900 focus:shadow-outline-green active:bg-green-800"
                      >
                        Promover a capitan
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => updateMemberRole("subcapitán")}
                      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 focus:outline-none focus:border-green-900 focus:shadow-outline-green active:bg-green-800"
                    >
                      Promover a subcapitan
                    </button>
                  )}
                </div>
              )}
            </div>
          </Modal>

          <Modal
            isOpen={showTeamManagerModal}
            onRequestClose={closeTeamManagerModal}
            className="bg-fifth p-4 rounded-xl"
            style={{
              overlay: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
              content: {
                position: "relative",
                top: "auto",
                right: "auto",
                bottom: "auto",
                left: "auto",
                maxWidth: "80%", 
                width: "800px", 
                maxHeight: "90vh",  
                overflowY: "auto",
              },
            }}
          >
            <div className="flex flex-col items-center p-4 justify-center text-white">
              <Heading medium white>
                Configurar miembros
              </Heading>
              <div className="mt-4 w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                {" "}
                {teamData.members.map((member) => (
                  <div
                    key={member.id}
                    className="p-4 border-2 border-yellow-500 bg-black bg-opacity-50 rounded mb-4 flex flex-col md:flex-row justify-between items-start md:items-center"
                  >
                    <div className="mb-4 md:mb-0">
                      <p>
                        <span className="font-bold text-sm">ID:</span>{" "}
                        <span className="text-xs">{member.id}</span>
                      </p>
                      <p>
                        <span className="font-bold text-sm">Role:</span>{" "}
                        <span className="text-xs">{member.role}</span>
                      </p>
                      <p>
                        <span className="font-bold text-sm">Region:</span>{" "}
                        <span className="text-xs">{member.region}</span>
                      </p>
                      <p>
                        <span className="font-bold text-sm">Discord Tag:</span>{" "}
                        <span className="text-xs">{member.discord_tag}</span>
                      </p>
                      <p>
                        <span className="font-bold text-sm">
                          Summoner Name:
                        </span>{" "}
                        <span className="text-xs">
                          {member.soloq_account__summonerName}
                        </span>
                      </p>
                      <p>
                        <span className="font-bold text-sm">Tier:</span>{" "}
                        <span className="text-xs">
                          {member.soloq_account__tier}
                        </span>
                      </p>
                      <p>
                        <span className="font-bold text-sm">Rank:</span>{" "}
                        <span className="text-xs">
                          {member.soloq_account__rank}
                        </span>
                      </p>
                      <p>
                        <span className="font-bold text-sm">
                          League Points:
                        </span>{" "}
                        <span className="text-xs">
                          {member.soloq_account__leaguePoints}
                        </span>
                      </p>
                    </div>

                    <div className="flex flex-col gap-2">
                      {member.discord_tag !== teamData.captain[0] && (
                        <button
                          onClick={() => openRoleUpdateModal(member)}
                          className="bg-blue-500 text-white p-1 rounded mb-2 md:mb-0 text-xs"
                        >
                          Actualizar rango
                        </button>
                      )}
                      {member.discord_tag !== teamData.captain[0] && (
                        <button
                          onClick={() => removeMember(member.id)}
                          className="bg-red-500 text-white p-1 rounded text-xs"
                        >
                          Remover miembro
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={showLeaveModal}
            onRequestClose={() => setShowLeaveModal(false)}
            style={{
              overlay: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
              content: {
                position: "relative",
                top: "auto",
                right: "auto",
                bottom: "auto",
                left: "auto",
              },
            }}
          >
            <div className="flex flex-col items-center p-4 justify-center">
              <Heading medium>
                Estas seguro que quieres salir del equipo
              </Heading>
              <div className="flex mt-4">
                <button
                  onClick={confirmLeave}
                  className="mr-4 bg-red-500 text-white py-1 px-4 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
                >
                  Dejar el equipo
                </button>
                <button
                  onClick={cancelLeave}
                  className="bg-gray-500 text-white py-1 px-4 rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50"
                >
                  Quedarme
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={showRequestsModal}
            onRequestClose={() => setShowRequestsModal(false)}
            className="flex justify-center items-center h-[100vh] overflow-y-auto bg-opacity-60 bg-black"
          >
            <div className="relative max-w-[90vw] bg-fifth p-4 rounded-xl backdrop-blur-md  max-h-[80vh] overflow-y-auto">
              <button
                onClick={() => setShowRequestsModal(false)}
                className="absolute top-2 right-2 bg-red-500 hover:bg-red-700 text-white font-bold rounded-full w-6 h-6 flex items-center justify-center focus:outline-none"
              >
                X
              </button>
              <div className="flex justify-center mb-4">
                <Heading white large>
                  Solicitudes Pendientes
                </Heading>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {teamRequests.map((request) => (
                  <div
                    key={request.id}
                    className="flex flex-col items-center
                   bg-black bg-opacity-50 backdrop-blur-md 
                 rounded p-4 border-2 border-yellow-500 text-white"
                  >
                    <div>
                      <span className="font-bold text-sm">Discord: </span>
                      <span className="text-xs">{request.discord_tag}</span>
                    </div>
                    <div>
                      <span className="font-bold text-sm">Summoner: </span>{" "}
                      <span className="text-xs">
                        {request.soloq_account_info.summonerName}
                      </span>
                    </div>
                    <div>
                      <span className="font-bold text-sm">Region: </span>
                      <span className="text-xs">{request.region}</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="text-xs">
                        {request.soloq_account_info.tier}{" "}
                        {request.soloq_account_info.rank}
                      </span>
                      <img
                        src={getRankImage(request.soloq_account_info.tier)}
                        alt={request.soloq_account_info.tier}
                        width={80}
                        height={80}
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="text-xs">{request.role}</span>
                      <img
                        src={getRoleImage(request.role)}
                        alt={request.role}
                        width={50}
                        height={50}
                      />
                    </div>
                    <div>
                      <span className="font-bold text-sm">Estado:</span>{" "}
                      <span className="text-xs">{request.request_status}</span>
                    </div>
                    <div className="flex mt-2">
                      <button
                        onClick={() => handleAcceptRequest(request.id)}
                        className="mr-2 bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 text-xs"
                      >
                        Aceptar
                      </button>
                      <button
                        onClick={() => handleRejectRequest(request.id)}
                        className="bg-red-500 text-white py-1 px-4 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 text-xs"
                      >
                        Rechazar
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={showDeleteConfirmationModal}
            onRequestClose={() => setShowDeleteConfirmationModal(false)}
            style={{
              overlay: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)", 
              },
              content: {
                position: "relative",
                top: "auto",
                right: "auto",
                bottom: "auto",
                left: "auto",
              },
            }}
          >
            <div className="flex flex-col items-center p-4 justify-center">
              <Heading medium>
                Estas seguro que quieres eliminar este equipo?
              </Heading>
              <div className="flex mt-4">
                <button
                  onClick={deleteTeam}
                  className="mr-4 bg-red-500 text-white py-1 px-4 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
                >
                  Yes
                </button>
                <button
                  onClick={() => setShowDeleteConfirmationModal(false)}
                  className="bg-gray-500 text-white py-1 px-4 rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50"
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      )}
      {isProcessing && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      )}
      {isDeleting && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      )}
      {isRemoving && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default TeamPage;
