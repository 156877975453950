import React, { useState, useEffect } from "react";
import Axios from "axios";
import Modal from "react-modal";
import Loader from "../../components/Loader";
import CardsHeading from "../../components/CardsHeading";
import AgentCard from "./AgentCard";
import ActionButton from '../../components/ActionButton'

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const AgentsList = ({ agents, userData, user, isLoading, invitations }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [region, setRegion] = useState("ALL");
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [invitationStatus, setInvitationStatus] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isInviting, setIsInviting] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalWithContent = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!agents || agents.length === 0) {
      return; 
    }

    let result = agents.filter((agent) => agent.status === "SIN_EQUIPO");

    if (searchTerm) {
      result = result.filter((agent) =>
        agent.discord_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (region !== "ALL") {
      result = result.filter((agent) => agent.region === region);
    }

    setFilteredAgents(result);
  }, [searchTerm, region, agents]);

  const sendInvitation = (agentId, agentName) => {
    setIsInviting(true);

    const data = {
      invited_player_id: agentId,
    };

    Axios.post("/team-invite/create/", data)
      .then((response) => {
        setInvitationStatus((prev) => ({ ...prev, [Number(agentId)]: "invited" }));
        openModalWithContent(`Has invitado a ${agentName} exitosamente!`);
      })
      .catch((error) => {
        console.error("Error sending invitation:", error);
        if (
          error.response &&
          error.response.data.detail ===
            "Ya existe una invitación pendiente para este usuario en el mismo equipo."
        ) {
          setInvitationStatus((prev) => ({
            ...prev,
            [agentId]: "already_invited",
          }));
        }
      })
      .finally(() => {
        setIsInviting(false);
      });
  };

  if (isLoading) {
    return (
      <div style={overlayStyle}>
        <Loader />
      </div>
    );
  }

  if (!isLoading && agents.length === 0) {
    return (
      <div className="text-center text-white mt-4">
        No hay free agents disponibles.
      </div>
    );
  }

  
  return (
    <div className="flex flex-col gap-2">
      <div className="filter-section flex gap-1 text-white">
        <input
          className="bg-transparent border p-1"
          type="text"
          placeholder="Buscar Agente"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          className="bg-transparent border p-1"
        >
          <option className="text-black" value="ALL">
            Todas las Regiones
          </option>
          <option className="text-black" value="LAN">
            LAN
          </option>
          <option className="text-black" value="LAS">
            LAS
          </option>
        </select>
      </div>
      <CardsHeading
        titles={["Jugador", "Region", "Rango", "Coins", "Invitar"]}
      />

      {isInviting && (
        <div style={overlayStyle}>
          <Loader />
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Invitation Modal"
        className="bg-fifth p-4 rounded-xl"
            style={{
              overlay: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
              content: {
                position: "relative",
                top: "auto",
                right: "auto",
                bottom: "auto",
                left: "auto",
                maxWidth: "80%",
                width: "800px",
              },
              
            }}
      >
        <div className="flex flex-col items-center text-white">
        <h2 className="text-lg font-bold">Estado de invitacion</h2>
        <div>{modalContent}</div>
        <ActionButton onClick={closeModal}>Cerrar</ActionButton>
        </div>
        
      </Modal>

      {filteredAgents.map((agent, index) => (
        <div key={index}>
          <AgentCard
            name={agent.discord_name}
            id={agent.user_id}
            agentId={agent.id}
            avatar={agent.avatar_id}
            role={agent.role}
            region={agent.region}
            tier={agent.tier}
            rank={agent.rank}
            lp={agent.league_points}
            summoner={agent.summoner_name}
            coins={agent.coins}
            invitationStatus={invitationStatus[Number(agent.id)]}
            onClick={() => sendInvitation(agent.id, agent.discord_name)}
            userData={userData}
            user={user}
            invitations={invitations}
          />
        </div>
      ))}
    </div>
  );
};

export default AgentsList;