import React from "react";

const Heading = ({ children, white, large, gray }) => {
  let textColor = 'text-black';
  
  if (white) {
    textColor = 'text-white';
  } else if (gray) {
    textColor = 'text-gray-500';
  }
  
  const textSize = large ? 
    'text-xl sm:text-2xl md:text-3xl lg:text-4xl' : 
    'text-lg sm:text-xl md:text-2xl lg:text-3xl';
  
  return (
    <div className={`${textSize} ${textColor}`}>
      {children}
    </div>
  );
};

export default Heading;
