import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsFillClipboard2CheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Notifications = ({ notifications, onNotificationsUpdate }) => {
  const navigate = useNavigate();

  const sortedAndFilteredNotifications = notifications
    .sort((a, b) => {
      if (a.is_read === b.is_read) return 0; 
      if (a.is_read) return 1; 
      return -1; 
    })
    .slice(0, 20); 

  const markNotificationAsRead = (id) => {
    axios
      .post("/api/mark-read-notification/", { id })
      .then((response) => {
        const updatedNotifications = notifications.map((notification) =>
          notification.id === id
            ? { ...notification, is_read: true }
            : notification
        );
        onNotificationsUpdate(updatedNotifications);
      })
      .catch((error) =>
        console.error("Error marking notification as read:", error)
      );
  };

  const markAllAsRead = () => {
    axios
      .post("/api/mark-read-all-notifications/")
      .then((response) => {
        const updatedNotifications = notifications.map((notification) => ({
          ...notification,
          is_read: true,
        }));
        onNotificationsUpdate(updatedNotifications);
      })
      .catch((error) =>
        console.error("Error marking all notifications as read:", error)
      );
  };

  return (
    <div className="bg-fifth border rounded shadow-lg text-white flex flex-col gap-2 items-center max-h-max p-2">
      <button
        onClick={markAllAsRead}
        className="px-0.5 py-0.5 bg-blue-500 text-white rounded text-xs w-full"
      >
        Mark all as read
      </button>
      {sortedAndFilteredNotifications.length === 0 ? (
        <div className="px-1 py-1 text-sm">
          No tienes ninguna notificacion todavia.
        </div>
      ) : (
        sortedAndFilteredNotifications.map((notification) => (
          <div
            key={notification.id}
            className="px-0.5 py-0.5 fill-hover border-b flex justify-between items-center w-full cursor-pointer"
            onClick={() => {
              switch (notification.type) {
                case "SCRIMS_INVITATION":
                  navigate("/league/scrims/invitations-received");
                  break;
                case "SCRIMS_REQUESTS":
                  navigate("/league/scrims/myteam");
                  break;
                case "SCRIMS_SCHEDULE":
                  navigate("/league/scrims/matches");
                  break;
                default:
                  navigate("/league/scrims/matches");
                  break;
              }
              markNotificationAsRead(notification.id);
            }}
          >
            <div className="flex items-center">
              {!notification.is_read && (
                <span className="mr-2 bg-red-500 rounded-full w-3 h-3 inline-block"></span>
              )}
              <div className="flex-grow">
                <div className="text-xs">{notification.message}</div>
              </div>
            </div>
            {!notification.is_read && (
              <BsFillClipboard2CheckFill
                onClick={() => markNotificationAsRead(notification.id)}
                className="text-blue-500 cursor-pointer ml-2"
                size={20}
              />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default Notifications;
