import React, { useState } from "react";
import { AiOutlineCalendar, AiOutlineTeam } from "react-icons/ai";
import { IoTicketOutline } from "react-icons/io5";
import { MdOutlineRule } from "react-icons/md";
import axios from "axios";
import Loader from "../../components/Loader";
import ModalRules from "./ModalRules";
import ModalTeams from "./ModalTeams";
import BanSummonerSpellModal from "./BanSummonerSpellModal";
import BanChampionModal from "./BanChampionModal";
import CancelRuleModal from "./CancelRulesModal";

export const TournamentCard = ({
  name,
  price,
  startDate,
  endDate,
  id,
  joined,
}) => {
  const formattedStartDate = new Date(startDate).toLocaleDateString();
  const formattedEndDate = new Date(endDate).toLocaleDateString();

  const entryCostText = price === 1 ? "1 Ticket" : `${price} Tickets`;

  const [tier1Enabled, setTier1Enabled] = useState(true);
  const [tier2Enabled, setTier2Enabled] = useState(true);
  const [tier3Enabled, setTier3Enabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [teamsModalOpen, setTeamsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [banChampionModalOpen, setBanChampionModalOpen] = useState(false);
  const [banSummonerSpellModalOpen, setBanSummonerSpellModalOpen] =
    useState(false);

  const [CancelRuleModalOpen, setCancelRuleModalOpen] = useState(false);

  const handleJoinTournament = async () => {
    try {
      setLoading(true);
      const response = await axios.post("api/join-tournament/", { id });
      setModalContent(response.data.detail);
      setModalTitle("Unirse al torneo");
      setModalOpen(true);
    } catch (error) {
      setModalContent(
        error.response?.data?.detail || "Error al unirse al torneo"
      );
      setModalTitle("Error al unirse");
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRuleTier3 = async () => {
    setImageUrl("");

    try {
      setLoading(true);
      const response = await axios.post(
        "tournament/exchange/rule-set-tier-3/",
        { tournament_id: id }
      );
      setModalContent(response.data.detail);
      if (response.data.image_url) {
        setImageUrl(response.data.image_url);
      }
      setModalTitle("Ha añadido una nueva regla al torneo");
      setModalOpen(true);
    } catch (error) {
      setModalContent(
        error.response?.data?.detail || "Error al añadir regla al torneo"
      );
      setImageUrl("");

      setModalTitle("Error al añadir regla al torneo");
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRuleTier2 = async () => {
    setImageUrl("");

    try {
      setLoading(true);
      const response = await axios.post(
        "tournament/exchange/rule-set-tier-2/",
        { tournament_id: id }
      );
      setModalContent(response.data.detail);
      if (response.data.image_url) {
        setImageUrl(response.data.image_url);
      }
      setModalTitle("Ha añadido una nueva regla Nivel 2 al torneo");
      setModalOpen(true);
    } catch (error) {
      setModalContent(
        error.response?.data?.detail ||
          "Error al añadir regla Nivel 2 al torneo"
      );
      setImageUrl("");
      setModalTitle("Error al añadir regla Nivel 2 al torneo");
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };
  const handleAddRuleTier1 = async () => {
    setImageUrl("");

    try {
      setLoading(true);
      const response = await axios.post(
        "tournament/exchange/rule-set-tier-1/",
        { tournament_id: id }
      );
      setModalContent(response.data.detail);
      if (response.data.image_url) {
        setImageUrl(response.data.image_url);
      }
      setModalTitle("Ha añadido una nueva regla Nivel 3 al torneo");
      setModalOpen(true);
    } catch (error) {
      setModalContent(
        error.response?.data?.detail ||
          "Error al añadir regla Nivel 3 al torneo"
      );
      setImageUrl("");
      setModalTitle("Error al añadir regla Nivel 3 al torneo");
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };
  const closeModal = () => {
    setModalOpen(false);
    setModalContent("");
    setModalTitle("");
    window.location.reload();
  };
  const [rulesModalOpen, setRulesModalOpen] = useState(false);

  const openRulesModal = () => {
    setRulesModalOpen(true);
  };

  const closeRulesModal = () => {
    setRulesModalOpen(false);
  };

  const openTeamsModal = () => {
    setTeamsModalOpen(true);
  };

  const closeTeamsModal = () => {
    setTeamsModalOpen(false);
  };

  const openBanChampionModal = () => {
    setBanChampionModalOpen(true);
  };

  const openBanSummonerSpellModal = () => {
    setBanSummonerSpellModalOpen(true);
  };

  const openCancelRuleModal = () => {
    setCancelRuleModalOpen(true);
  };
  const currentDate = new Date();

  const isStarted = new Date(startDate) < currentDate;

  return (
    <div className="bg-fifth p-4 rounded-xl border border-yellow-500 border-opacity-50 hover:border-opacity-100">
      {loading && ( 
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <Loader />
        </div>
      )}
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <div className="flex flex-col">
            <div className="text-lg font-bold text-white mb-2">{name}</div>
            <div className="text-base text-white flex items-center mb-2">
              <IoTicketOutline className="mr-1" />
              Costo de entrada: {entryCostText}
            </div>
            <div className="text-base text-white flex items-center mb-2">
              <AiOutlineCalendar className="mr-1" />
              Start Date: {formattedStartDate}
            </div>
            <div className="text-base text-white flex items-center mb-2">
              <AiOutlineCalendar className="mr-1" />
              End Date: {formattedEndDate}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <img
            src="https://cdn.discordapp.com/attachments/1107721181609599060/1177387126132846673/FinalLogoDetail.png?ex=65725235&is=655fdd35&hm=870e049fb74142754bf2f273232ae1ba2fe9921d56ce64e227708684bdf74413"
            alt="Logo"
            className="w-full h-auto object-contain max-h-28"
          />
        </div>
      </div>
      <div className="flex flex-col mt-4 gap-2">
        <div className="grid grid-cols-3 gap-4">
          <button
            className={`text-base px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty ${
              joined || isStarted
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-yellow-500 text-white"
            }`}
            onClick={handleJoinTournament}
            disabled={loading || joined || isStarted}
          >
            <IoTicketOutline className="mr-2" />
            <span className="flex-1 text-center">
              {isStarted ? "Iniciado" : joined ? "Ya te has unido" : "Unirse"}
            </span>
          </button>
          <button
            className={`text-base bg-yellow-500 text-white px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty`}
            onClick={openRulesModal}
          >
            <MdOutlineRule className="mr-2" />
            <span className="flex-1 text-center">Ver Reglas</span>
          </button>
          {rulesModalOpen && (
            <ModalRules tournamentId={id} closeModal={closeRulesModal} />
          )}
          <button
            className={`text-base bg-yellow-500 text-white px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty`}
            onClick={openTeamsModal}
          >
            <AiOutlineTeam className="mr-2" />
            <span className="flex-1 text-center">Teams</span>
          </button>
          {teamsModalOpen && (
            <ModalTeams tournamentId={id} closeModal={closeTeamsModal} />
          )}
          <button
            className={`text-base px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty ${
              tier3Enabled
                ? "bg-yellow-500 text-white "
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            onClick={handleAddRuleTier3}
            disabled={!tier3Enabled}
          >
            <MdOutlineRule className="mr-2" />
            <span className="flex-1 text-center">Añadir Regla Nivel 1</span>
          </button>
          <button
            className={`text-base px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty ${
              tier2Enabled
                ? "bg-yellow-500 text-white "
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            onClick={handleAddRuleTier2}
            disabled={!tier2Enabled}
          >
            <MdOutlineRule className="mr-2" />
            <span className="flex-1 text-center">Añadir Regla Nivel 2</span>
          </button>
          <button
            className={`text-base px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty ${
              tier1Enabled
                ? "bg-yellow-500 text-white "
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            onClick={handleAddRuleTier1}
            disabled={!tier1Enabled}
          >
            <MdOutlineRule className="mr-2" />
            <span className="flex-1 text-center">Añadir Regla Nivel 3</span>
          </button>
          <button
            className={`text-base px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty bg-yellow-500 text-white`}
            onClick={openBanChampionModal}
          >
            <span className="flex-1 text-center">Banear Campeón Target</span>
          </button>
          <button
            className={`text-base px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty bg-yellow-500 text-white`}
            onClick={openBanSummonerSpellModal}
          >
            <span className="flex-1 text-center">Banear Hechizo Target</span>
          </button>
          <button
            className={`text-base px-4 py-2 rounded-md w-full flex items-center justify-center custom-wrap-pretty bg-yellow-500 text-white`}
            onClick={openCancelRuleModal}
          >
            <span className="flex-1 text-center">Cancelar Regla</span>
          </button>
        </div>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
            <h3 className={`font-bold text-lg`}>{modalTitle}</h3>
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Imagen"
                className="rounded-full w-8 h-8 sm:w-10 sm:h-10 bg-gray-400 max-w-none bg-opacity-30 my-4"
              />
            )}{" "}
            <p>{modalContent}</p>
            <button
              onClick={closeModal}
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
      {banChampionModalOpen && (
        <BanChampionModal
          tournamentId={id}
          closeModal={() => setBanChampionModalOpen(false)}
        />
      )}

      {banSummonerSpellModalOpen && (
        <BanSummonerSpellModal
          tournamentId={id}
          closeModal={() => setBanSummonerSpellModalOpen(false)}
        />
      )}

      {CancelRuleModalOpen && (
        <CancelRuleModal
          tournamentId={id}
          closeModal={() => setCancelRuleModalOpen(false)}
        />
      )}
    </div>
  );
};

export default TournamentCard;
