import React from "react";
import { SiMcdonalds } from "react-icons/si";
import Images from "./ImagesContainer";
import { useNavigate, Link } from "react-router-dom";
import TransferCard from "./TransferCard";
import CardsHeading from "./CardsHeading";
import { useMediaQuery } from "@react-hook/media-query";

const Transfers = ({ transfers, day }) => {
  const daysToShow = day ? 1 : 7;
  const isMobile = useMediaQuery("(max-width: 768px)"); 
  const iconSize = isMobile ? 25 : 50; 
  transfers.sort((a, b) => b.timestamp - a.timestamp);

  let recentTransfers = transfers.filter((transfer, index) => {
    if (day) {
      return index < 2;
    } else {
      const transferDate = new Date(transfer.timestamp);
      const currentDate = new Date();
      const differenceInDays =
        (currentDate - transferDate) / (1000 * 60 * 60 * 24);
      return differenceInDays <= daysToShow;
    }
  });

  if (recentTransfers.length === 0) {
    recentTransfers = transfers;
  }

  const navigate = useNavigate();

  const formattedTransfers = recentTransfers.map((transfer) => {
    const isFreeAgentFrom = transfer.status === "EN_EQUIPO" ? true : false;

    const TeamComponent = ({ teamName, imgSrc }) => (
      <div
        className="flex flex-col cursor-pointer hover:text-red-300 hover:underline
        items-center"
        onClick={() => goToTeamPage(teamName)}
      >
        <img
          src={imgSrc}
          alt={teamName}
          className="rounded-full w-12 sm:w-20 h-12 sm:h-20 object-contain"
        />
        <div>{teamName}</div>
      </div>
    );

    return {
      date: new Date(transfer.timestamp).toISOString().split("T")[0],
      player: transfer.user_name,
      from: isFreeAgentFrom ? (
        <div className="flex flex-col gap-1 items-center">
          <Link to="/league/free-agents">
            <SiMcdonalds size={iconSize} color="yellow" /> F/A
          </Link>
        </div>
      ) : (
        <TeamComponent
          teamName={transfer.team}
          imgSrc={Images[transfer.team_image]}
        />
      ),
      to: isFreeAgentFrom ? (
        <TeamComponent
          teamName={transfer.team}
          imgSrc={Images[transfer.team_image]}
        />
      ) : (
        <div className="flex flex-col gap-1 items-center">
          <Link to="/league/free-agents">
            <SiMcdonalds size={iconSize} color="yellow" /> F/A
          </Link>
        </div>
      ),
      discordId: transfer.user_discord_id,
      discordAvatar: transfer.user_discord_avatar,
      tier: transfer.user_soloq_tier,
      rank: transfer.user_soloq_rank,
      lp: transfer.user_soloq_leaguePoints,
      summonerName: transfer.user_soloq_summonerName,
      role: transfer.user_role,
      type: isFreeAgentFrom,
      region: transfer.user_region,
    };
  });

  const goToTeamPage = (teamName) => {
    navigate(`/league/equipos/${teamName}`);
  };

  return (
    <div className="flex flex-col gap-2">
      <CardsHeading
        titles={["FECHA", "DISCORD", "INVOCADOR", "DESDE", "", "HACIA"]}
      />
      {formattedTransfers.map((transfer, index) => (
        <div key={index}>
          <TransferCard
            date={transfer.date}
            player={transfer.player}
            discordId={transfer.discordId}
            discordAvatar={transfer.discordAvatar}
            tier={transfer.tier}
            rank={transfer.rank}
            lp={transfer.lp}
            summonerName={transfer.summonerName}
            role={transfer.role}
            fa={transfer.type}
            from={transfer.from}
            to={transfer.to}
            region={transfer.region}
          />
        </div>
      ))}
    </div>
  );
};

export default Transfers;
