import React from "react";

export const Paragraph = ({ children, white }) => {
  return (
    <div
      className={`
        font-normal 
        text-sm
        sm:text-base
        md:text-md
        lg:text-lg 
        ${white ? 'text-white' : 'text-black'}
      `}
    >
      {children}
    </div>
  );
};
