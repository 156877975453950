import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { MdManageAccounts } from "react-icons/md";
import SelectGame from "./SelectGame";
import lol from "../../assets/icons8-league-of-legends-100.png";
import valorant from "../../assets/icons8-valorant-100.png";
import fifa from "../../assets/icons8-fifa-21-100.png";
import Verify from "./Verify";
import Region from "./Region";
import Role from "./Role";
import ActionButton from "../../components/ActionButton";
import ProfileModal from "../../components/ProfileModal";
import Loader from "../../components/Loader";
import Offers from "./Offers";
import DiscordCheck from "./DiscordCheck";
import CountryFlagSelector from "../../components/CountryFlagSelector";

const Index = ({ userData }) => {
  const [game, setGame] = useState(null);
  const [region, setRegion] = useState(null);
  const [role, setRole] = useState(null);
  const [receiveOffers, setReceiveOffers] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [check, setCheck] = useState(false);
  const [isProfileConfigVisible, setIsProfileConfigVisible] = useState(true);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const [userInfo, setUserInfo] = useState({
    verified: false,
  });
  const games = [
    { id: "lol", name: "LoL", logo: lol, enabled: true },
    { id: "valorant", name: "Valorant", logo: valorant, enabled: false },
    { id: "fifa", name: "FIFA", logo: fifa, enabled: false },
  ];

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch("/api/check_user_profile/");
        const data = await response.json();

        if (data.has_profile) {
          setRegion(data.user_profile.region);
          setRole(data.user_profile.role);
          setReceiveOffers(data.user_profile.receiving_offers);
          setGame("lol");
          setSelectedCountryCode(data.user_profile.country)
        } else {
        }

        setUserInfo({ verified: data.has_profile });
        setUser(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch user profile", error);
        setIsLoading(false);
      }
    };
    fetchUserProfile();
  }, []);

  const handleSaveProfile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/api/update-user-profile/", {
        role: role,
        region: region,
        receiving_offers: receiveOffers,
        country: selectedCountryCode,

      });
      setIsLoading(false);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      setIsLoading(false);
    }
  };

  if (!userData || !userData.id) {
    return (
      <Container>
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      </Container>
    );
  }

  const id = userData.id;
  const avatar = userData.avatar;
  const image = avatar
    ? `https://cdn.discordapp.com/avatars/${id}/${avatar}.png`
    : "https://i.redd.it/j3t4cvgywd051.png";

  const discord_name = userData
    ? userData.discord_tag.charAt(0).toUpperCase() +
      userData.discord_tag.slice(1)
    : null;
  return (
    <Container>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-2 mb-2">
          <TitleContainer
            text={"Configuración de Perfil"}
            icon={<MdManageAccounts />}
            color="--primary-color"
            flex="end"
          />

          {isProfileConfigVisible ? (
            <div className="bg-secondary p-8 rounded">
              <div className="text-center font-bold text-5xl text-white my-8">
                Bienvenido a tu perfil
              </div>
              <div className="flex items-center justify-center mb-4">
                <div className="h-44 w-44 rounded-full overflow-hidden bg-secondary">
                  <img
                    src={image}
                    alt="Avatar de Discord"
                    className="w-full h-full object-cover border-4 border-yellow-500 rounded-full"
                  />
                </div>
              </div>
              <div className="text-center font-semibold text-white my-4 text-xl">
                {userData ? discord_name : "Nombre de usuario"}
              </div>
              <div className="flex items-center justify-center mb-4">
                <CountryFlagSelector 
                  user_country={selectedCountryCode} 
                  onCountrySelect={setSelectedCountryCode} 
                />
              </div>
              <div className="text-center text-white font-bold mt-4 mb-4">
                Debes verificar tu permanencia en el Discord siempre que vayas a
                realizar un cambio en tu perfil.
              </div>
              <div className="flex flex-row items-center justify-center mb-4 gap-4">
                <DiscordCheck check={check} setCheck={setCheck} />
                <Verify check={check} user={userInfo} data={user} />
              </div>

              <div className="grid lg:grid-cols-4 md:grid-cols-2  grid-cols-2 gap-4">
                <SelectGame
                  games={games}
                  setGame={setGame}
                  selectedGame={game}
                />
                <Region setRegion={setRegion} region={region} />
                <Role selectedRole={role} setRole={setRole} />
                <Offers
                  setReceiveOffers={setReceiveOffers}
                  selectedReceiveOffers={receiveOffers}
                />
              </div>
              <div className="flex items-center justify-center mt-4">
                <ActionButton
                  disabled={
                    !region || !role || !game || !userInfo.verified || !check
                  }
                  onClick={handleSaveProfile}
                  className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                >
                  Guardar Perfil
                </ActionButton>
              </div>
              <ProfileModal
                isOpen={isModalVisible}
                onClose={() => {
                  setIsModalVisible(false);
                }}
                game={game}
                region={region}
                role={role}
                receiving_offers={receiveOffers}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center mt-4">
              <ActionButton
                primary
                onClick={() => setIsProfileConfigVisible(true)}
              >
                Configurar perfil
              </ActionButton>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default Index;
