import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/Loader";

import { useNavigate } from "react-router-dom";

const ModalTeams = ({ tournamentId, closeModal }) => {
  const [tournamentInfo, setTournamentInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchTournamentInfo = async () => {
    try {
      const response = await axios.get(`/api/tournament/${tournamentId}`);
      setTournamentInfo(response.data);
    } catch (error) {
      console.error("Error al obtener la información del torneo:", error);
      setTournamentInfo({});
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();

  const goToTeamPage = (teamName) => {
    navigate(`/league/equipos/${teamName}`);
  };

  useEffect(() => {
    fetchTournamentInfo();
  }, [tournamentId]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center w-full md:w-5/12 lg:w-5/12 h-5/6 overflow-auto">
        <h3 className="font-bold text-lg mb-4">Lista de Equipos Registrados</h3>

        <div className="modal-content w-full h-full overflow-auto">
          {loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : (
            <table className="w-full">
              <thead className="mb-2">
                <tr>
                  <th className="text-yellow-500 font-bold text-center">
                    Nombre del Equipo
                  </th>
                  <th className="text-yellow-500 font-bold text-center">
                    Detalles
                  </th>
                </tr>
              </thead>
              <tbody>
                {tournamentInfo.teams &&
                  tournamentInfo.teams.map((teamName, index) => (
                    <tr key={index}>
                      <td
                        className="text-center cursor-pointer hover:underline"
                        onClick={() => goToTeamPage(teamName)}
                      >
                        {teamName}
                      </td>
                      <td className="text-center flex items-center justify-center">
                        <button
                          className="text-base  px-4 py-2 rounded-md w-3/6 flex items-center justify-center custom-wrap-pretty bg-yellow-500 text-white"
                          onClick={() => goToTeamPage(teamName)}
                        >
                          Ver Equipo
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>

        <button
          onClick={closeModal}
          className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ModalTeams;
